import React, { Component } from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import *as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import Tablepay from './TablePays';
import * as ROLES from '../../constants/roles';

import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import { withStyles } from '@material-ui/core/styles';


import _ from 'lodash'
import TablePays from './TablePays';



const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
};

const INITIAL_STATE = {
    username: '',
    email: '',
    rol: ROLES.BRAND,
    error: null,
    id:"",
    loading: false,
    cargado: false,
};

class ClientProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE
        };
    }



    componentDidMount() {
        this.setState({ loading: true });

        let id = 0;
        let idCamp = 0;
        if (!this.props.id) { id = this.props.idC.uid; }
        else id = this.props.id;

        this.props.firebase.user(id).on('value', snapshot => {
            const usersObject = snapshot.val();
            console.log(usersObject);


            this.setState({
                campData: usersObject,

                username: usersObject.username,
                rol: usersObject.rol,
                email: usersObject.email,
                id: id,
                loading: false,
                cargado: true,
            });
        });
    }

    componentWillUnmount() {
        this.props.firebase.users().off();
    }


    render() {
        const { users, loading ,cargado} = this.state;
        const { classes } = this.props;
        return (
            <div>
                {loading && <div>Loading ...</div>}
            <AuthUserContext.Consumer>

                    {authUser =>
                        authUser.rol == ROLES.ADMIN ? (


                            <div>
                                <h1>Perfil CLIENTE</h1>

                                <div className="row">
                                    <div className="col s5">

                                        <div className=" center  " style={{ margin: "10px", borderStyle: "solid" }}>
                                            Nombre:  {this.state.username}
                                        </div>

                                        <div className=" center  " style={{ margin: "10px", borderStyle: "solid" }}>
                                            Email:  {this.state.email}
                                        </div>

                                        <div className=" center  " style={{ margin: "10px", borderStyle: "solid" }}>
                                            rol:  {this.state.rol}
                                        </div>
                                    </div>
                                    <div className="col s5 offset-s1 ">
                                        <div className=" center  " style={{ margin: "10px", borderStyle: "solid" }}>
                                            <h3> Historial de Pagos</h3>
                                            <Tablepay id={this.props.id} />
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div>

                                    </div>
                                </div>
                            </div>


                        ) : (
                                <div>

                                <GridContainer
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start">
                                    <GridItem xs={12} lg={5}>
                                    <Card >
                                        <CardHeader>
                                          <CardIcon color="danger">
                                            <h4 className={classes.cardTitleWhite}>Perfil</h4>
                                            <p className={classes.cardCategoryWhite}>Datos de registro</p>
                                          </CardIcon>
                                        </CardHeader>

                                        <CardBody>
                                            <GridContainer container
                                                direction="column"
                                                justify="space-around"
                                                alignItems="center" xs={12}>

                                                    <CustomInput
                                                        labelText="Nombre de usuario"

                                                        value={this.state.username}
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            onChange: this.onChange,
                                                            type: "text",
                                                            name: "username",
                                                            value: this.state.username
                                                        }}
                                                    />

                                                    <CustomInput
                                                        labelText="Email"

                                                        value={this.state.email}
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            onChange: this.onChange,
                                                            type: "text",
                                                            name: "email",
                                                            value: this.state.email ,
                                                            disabled: true
                                                        }}
                                                    />
                                                    <CustomInput
                                                        labelText="rol"

                                                        value={this.state.rol}
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            onChange: this.onChange,
                                                            type: "text",
                                                            name: "rol",
                                                            value: this.state.rol ,
                                                            disabled: true
                                                        }}
                                                    />

                                            </GridContainer>
                                        </CardBody>
                                        </Card>
                                        </GridItem>
                                        { /*
                                    <GridItem xs={12} lg={7}>
                                    <Card >
                                        <CardHeader>
                                          <CardIcon color="danger">
                                            <h4 className={classes.cardTitleWhite}>Pagos</h4>
                                            <p className={classes.cardCategoryWhite}></p>
                                          </CardIcon>
                                        </CardHeader>

                                        <CardBody>
                                            <GridContainer container
                                                direction="row"
                                                justify="space-around"
                                                alignItems="center" xs={12}>

                                                    <GridItem xs={10}>

                                                            {cargado && <TablePays id={this.state.id} />}


                                                    </GridItem>

                                            </GridContainer>
                                        </CardBody>
                                        </Card>
                                    </GridItem>
                                        */}
                                </GridContainer>
                                <GridContainer
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start">

                                    <GridItem xs={12} lg={8}>
                                        <PasswordForgetForm />
                                        <PasswordChangeForm />
                                    </GridItem>

                                </GridContainer>
                                </div>
                            )
                }
            </AuthUserContext.Consumer>
         </div>

        );
    }
}



const condition = authUser =>
    authUser && (authUser.rol == ROLES.ADMIN || authUser.rol == ROLES.BRAND);//[ROLES.ADMIN];
export default compose(
    withAuthorization(condition),
    withStyles(styles),
    withFirebase)
    (ClientProfile);
