// JavaScript source code
import React, { Component } from 'react';
import *as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import Tooltip from "@material-ui/core/Tooltip";
import { compose } from 'recompose';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardIcon from "components/Card/CardIcon";
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';

import { cardTitle } from "assets/jss/material-kit-react.js";
import poster from "../Clientes/img/meca.png";
import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { withFirebase } from '../Firebase';
const styles = {
    cardTitleRed: {
        color: "#FF0000",
        fontFamily: "'Roboto' sans-serif",
    },
    ...imagesStyles,
    textRight: {
        textAlign: "right"
    }
};

const useStyles = makeStyles(styles);

function SampleFab(props) {
    const classes = useStyles();
    return (
        <Card>
            <CardHeader style = {{ padding: "1.75rem 15px"}} >
              <CardIcon color="danger">
                <VideogameAssetIcon style = {{ fontSize: 40, color: "#ffffff"}}/>
              </CardIcon>
              <h3 align="right" className={classes.cardTitleRed}>{props.name}</h3>
            </CardHeader>
            <CardBody>
                <CardActionArea>
                    <a id="linkJuego" href={props.link} target="_blank" >
                        <img className={classes.imgCardTop} src={props.img} alt="Card-img-cap" onLoad={props._onLoadGame} style={{ display: props.loadImg }} />
                        {!props.loadImg &&
                            <GridContainer direction="row"
                                justify="center"
                                alignItems="stretch" >
                                <GridItem xs={12}>
                                    <CircularProgress color="danger" size={"100%"} />
                                </GridItem>
                            </GridContainer>
                        }
                    </a>
                </CardActionArea>
                <p style = {{ margin: "20px"}}>
                    {props.description}
                </p>

            </CardBody>
        </Card>
    );
}

class CardGame extends Component {
    constructor(props) {
        super(props);
        this.state = {
            urlImg: "",
            loadGameImg: false,
        };
    }

    componentDidMount() {
      console.log(this.props.img)
        this.props.firebase.getGameImg(this.props.img)
            .then((downloadURL)=> {
            console.log(downloadURL);
            this.setState({ urlImg: downloadURL });
        });
    }

    componentWillUnmount() {
        this.props.firebase.experience().off();
    }

    _onLoadGame = () => {
        this.setState(() => ({ loadGameImg: true }))
    }

    render() {
        return (
            <div>
                <SampleFab name={this.props.name} link={this.props.link} description={this.props.description} img={this.state.urlImg} _onLoadGame={this._onLoadGame} loadImg={this.state.loadGameImg} />
            </div>
          );
    }

}
export default compose(
    withFirebase)
    (CardGame);
