import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
//import { Dropdown } from 'semantic-ui-react';
import { withFirebase } from '../Firebase';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
};



const SignUpPage = () => (
  <div>
        <h1>Registro de usuarios</h1>

         <SignUpForm/>
  </div>
);

const INITIAL_STATE = {
    username: '',
    fullname: '',
    commercialname: '',
    address: '',
    country: '',
    type:'',
    email: '',
    passwordOne: '',
    passwordTwo: '',
    rol: ROLES.BRAND,
    error: null,
};


class SignUpFormBase extends Component {
  constructor(props) {
      super(props);
      this.state = { ...INITIAL_STATE }
  }
    onSubmit = event => {
        const { username, email, passwordOne, fullname,
            commercialname,
            address,
            country,
            type, rol } = this.state;
        this.props.firebase
            .doCreateUserWithEmailAndPassword(email, passwordOne)
            .then(authUser => {
                // Create a user in your Firebase realtime database
                return this.props.firebase
                    .user(authUser.user.uid)
                    .set({
                        username,
                        fullname,
                        commercialname,
                        address,
                        country,
                        type,
                        email,
                        rol,
                    });
            })
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                this.props.history.push(ROUTES.HOME);
            })
            .catch(error => {
                this.setState({ error });
            });
        event.preventDefault();
  }
    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    typeChange = (e, { value }) => {
        console.log(e.target.value);
        this.setState({ type: e.target.value  });
    }

    render() {
    const { classes } = this.props;

        const isInvalid =
            this.state.passwordOne !== this.state.passwordTwo ||
            this.state.passwordOne === '' ||
            this.state.email === '' ||
            this.state.username === '';
        const opctype = [<MenuItem value="Empresa">Empresa</MenuItem>, <MenuItem value="Agencia">Agencia</MenuItem>, <MenuItem value="Otro">Otro</MenuItem>];
       // const opctype = [{ key: 0, text: "Empresa", value: "Empresa" }, { key: 1, text: "Agencia", value: "Agencia" }, { key: 2, text: "Otro", value: "Otro" }]

    return (
        <form onSubmit={this.onSubmit}>
            <GridContainer xs={12} sm={10} md={8}>
                <Card >
                    <CardHeader>
                      <CardIcon color="danger">
                        <h4 className={classes.cardTitleWhite}>Registro</h4>
                        <p className={classes.cardCategoryWhite}>Registra trus datos</p>
                      </CardIcon>
                    </CardHeader>

                    <CardBody>
                        <GridContainer container
                            direction="row"
                            justify="space-around"
                            alignItems="center" xs={12}>


                            <GridItem>
                                <CustomInput
                                    labelText="Nombre de usuario"
                                    value={this.state.username}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: this.onChange,
                                        type: "text",
                                        name: "username"
                                    }}
                                />
                            </GridItem>
                            {/*
                             *    <input
                                    name="username"
                                    value={this.state.username}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder="Nombre de usuario"
                                />

                             */}
                            <GridItem>
                                <CustomInput
                                    labelText="Nombre comercial"
                                    value={this.state.commercialname}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: this.onChange,
                                        type: "text",
                                        name: "commercialname"
                                    }}
                                />
                            </GridItem>
                            {/*
                             *    <input
                                    name="commercialname"
                                    value={this.state.commercialname}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder="Nombre comercial"
                                />

                             */}

                            <GridItem>
                                <CustomInput
                                    labelText="Nombre  de contacto"
                                    value={this.state.fullname}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: this.onChange,
                                        type: "text",
                                        name: "fullname"
                                    }}
                                />
                            </GridItem>
                            {/*
                             * <input
                                    name="fullname"
                                    value={this.state.fullname }
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder="Nombre de contacto"
                                />

                             */}


                            <GridItem>
                                {/*<Dropdown
                                        placeholder='Tipo'
                                        fluid
                                        selection
                                        options={opctype}
                                        id="type"
                                        onChange={this.typeChange}
                                        value={this.state.type}

                                    />*/}
                                <FormControl fullWidth>
                                    <InputLabel id="TokensType">Tipo</InputLabel>
                                    <Select
                                        labelId="TokensType"
                                        id="type"
                                        value={this.state.type}
                                        onChange={this.typeChange}
                                    >
                                        {opctype}
                                    </Select>
                                </FormControl>
                            </GridItem>


                                <div className="col s8" >

                            </div>
                            <GridItem>
                                <CustomInput
                                    labelText="Ubicacion"
                                    value={this.state.address}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: this.onChange,
                                        type: "text",
                                        name: "address"
                                    }}
                                />
                            </GridItem>
                            {/*   <input
                                    name="address"
                                    value={this.state.address}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder="Ubicacion"
                                />

                             */}
                            <GridItem>
                                <CustomInput
                                    labelText="Pais"
                                    value={this.state.country}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: this.onChange,
                                        type: "text",
                                        name: "country"
                                    }}
                                />
                            </GridItem>
                            {/*
                               <input
                                    name="country"
                                    value={this.state.country}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder="Pais"
                                />
                             */}
                            <GridItem>
                                <CustomInput
                                    labelText="Email Address"
                                    value={this.state.email}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: this.onChange,
                                        type: "text",
                                        name: "email"
                                    }}
                                />
                            </GridItem>
                            {/*
                             *    <input
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder="Email Address"
                                />

                             */}
                            <GridItem>
                                <CustomInput
                                    labelText="Password"
                                    value={this.state.passwordOne}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: this.onChange,
                                        type: "password",
                                        name: "passwordOne"
                                    }}
                                />
                            </GridItem>
                            {/*   <input
                                    name="passwordOne"
                                    value={this.state.passwordOne}
                                    onChange={this.onChange}
                                    type="password"
                                    placeholder="Password"
                                />

                             */}
                            <GridItem>
                                <CustomInput
                                    labelText="Confirm Password"
                                    value={this.state.passwordTwo}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: this.onChange,
                                        type: "password",
                                        name: "passwordTwo"
                                    }}
                                />
                            </GridItem>
                            {/*
                               <input
                                    name="passwordTwo"
                                    value={this.state.passwordTwo}
                                    onChange={this.onChange}
                                    type="password"
                                    placeholder="Confirm Password"
                                />
                             */}


                            <GridItem>
                                <Button disabled={isInvalid} color="danger" type="submit">
                                    Sign Up
                                </Button>
                                {this.state.error && <p>{this.state.error.message}</p>}

                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>

            </GridContainer>

      </form>
    );
  }
}
const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

const SignUpForm = compose(
    withStyles(styles),
    withRouter,
    withFirebase,
)(SignUpFormBase);


export default SignUpPage;
export { SignUpForm, SignUpLink };
