import React, { Component } from 'react';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import * as ROLES from '../../constants/roles';
import *as ROUTES from '../../constants/routes';

//import { Icon, Label, Menu, Table } from 'semantic-ui-react';
//import { Button, Modal, Container, Dropdown, Input } from 'semantic-ui-react';
import PropTypes from "prop-types";
import Lens from "@material-ui/icons/Lens";

import Table from "@material-ui/core/Table";


import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash'

import PerfilCamp from "./PerfilCamp";
import PerfilClient from "./ClientProfile";

import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Button from 'components/CustomButtons/Button';


var tabla = [];
var tabla2 = [];
var tablaAsist = [];
var count = 0;
class TablePays extends Component {


    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };

    constructor(props) {
        super(props);
        //this.SelectAlumno = this.SelectAlumno.bind(this);


        //this.show = this.show.bind(this);
        this.close = this.close.bind(this);
        //this.Recargar = this.Recargar.bind(this);
        //this.eliminar = this.eliminar.bind(this);
        //this.switchLocal = this.switchLocal.bind(this);


       // this.chageAsis = this.chageAsis.bind(this);

        this.state = {
            contenido: [],
            open: false,
            size: 'tiny',
            nameModal: "",
            idModal: "",
            indiceModal: "",
            startDate: new Date(),
            tipoB: 1,
            espacio: 0,
            paginas: 0,
            pagN: 0,
            tamFilas: 5,
            inicio: true,
            pays:[],
            loading: false,
            TamFilasArray:[],
        }

        //console.log(this.props.clientes);
    }

    componentDidMount() {
        this.setState({ loading: true });
        console.log(this.props.id);
        this.props.firebase.user(this.props.id).orderByChild("campains").on('value', snapshot => {
            const usersObject = snapshot.val();
            console.log(usersObject);
            if (usersObject.campains) {
                const expList = [];
                const campList = Object.keys(usersObject.campains).map(key => ({
                    ...usersObject.campains[key],
                    uidCampain: key,

                }));
                console.log("aqui");
                console.log(campList);

                for (var i = 0; i < campList.length; i++) {
                    if (campList[i].Payments) {
                        const expL = Object.keys(campList[i].Payments).map(key => ({
                            ...campList[i].Payments[key],
                            Date: campList[i].Payments[key].date.Day + "/" + campList[i].Payments[key].date.Month + "/" + campList[i].Payments[key].date.Year,
                            uidCampain: campList[i].uidCampain,
                            uid: key
                        }))
                        for (var j = 0; j < expL.length; j++) {
                            //const id = expL[j].uidClient;
                            //const idCamp= expL[j].uid;
                            //const name= expL[j].nameUser;
                            //expL[j] = {
                            //    ...expL[j],
                            //    cellExp: <Table.Cell onClick={(e) => { this.SelectExp(e,id, idCamp, name) }} selectable>{expL[j].expName}</Table.Cell>,
                            //    cellClient: <Table.Cell onClick={(e) => { this.SelectCliente(e, id) }} selectable>{name}</Table.Cell>,

                            //}

                            expList.push(expL[j]);
                        }
                    }
                }

                console.log(expList);
                let np = Math.ceil(expList.length + 10 / this.state.tamFilas);


                let restan = (this.state.tamFilas - (expList.length - ((np - 1) * this.state.tamFilas)));
                console.log("vacios=  " + restan);
                if (expList.length == 0) restan = this.state.tamFilas;

                if (expList.length % this.state.tamFilas < this.state.tamFilas)
                    for (j = 0; j < restan; j++) {
                        const vacia2 = {
                            Mount: "", Date: "", status: "", uid: ""
                            //cellExp: <Table.Cell singleLine>  </Table.Cell>,
                            //cellClient: <Table.Cell singleLine>  </Table.Cell>,
                        }
                        expList.push(vacia2)

                    }
                let filasTam = [5];
                if (expList.length > 10) filasTam = [5, 10];
                if (expList.length > 25) filasTam = [5, 10, 25];
                if (expList.length > 100) filasTam = [5, 10, 25, 100];

                //console.log( expList );

                this.setState({
                    pays: expList,
                    loading: false,
                    paginas: np,
                    extraVoid: restan,
                    TamFilasArray: filasTam,
                }, () => this.setState({
                    open: false,
                    contenido: this.TablaInicial(0),
                    open: true,
                    inicio: false,

                }));

                console.log(this.state.users);

            }

        });
    }

    componentWillUnmount() {
        this.props.firebase.user().off();
    }

    //componentWillMount() {
    //    const para = { tipo: 2, algo: "" }
    //    tabla = [];
    //    tabla2 = [];

    //}

    show(e, id, activo) {

        //if (activo) {
        //    console.log("MOSTRAR!!!!!!!");
        //    this.setState({
        //        open:true,
        //    })
        //}
        this.setState({
            open: true,
            idModal: id,

        }, () => {
            this.setState({
                open: true,
                contenido: this.TablaInicial(this.state.pagN),

            })
        })
    }
    close() {
        this.setState({
            open: false,
        }
            , () => {
            this.setState({
                open: false,
                contenido: this.TablaInicial(this.state.pagN),
                open: true,
            })
        }
        );
    }



    //editarAlumno(e, id) {
    //    //console.debug("ID: " + id);
    //    this.setState({
    //       // contenido: <EditarAlumno id={id} atras={this.Recargar} />
    //    })
    //}



    //Ordenar = (e, { value }) => {
    //    //console.log(tabla);
    //    var tabla3 = [];
    //    tabla3 = _.compact(this.state.pays);

    //    if (this.state.extraVoid > 0) {
    //        tabla3 = _.dropRight(tabla3, this.state.extraVoid);
    //    }

    //    if (value == 4) {
    //        tabla3 = _.sortBy(tabla3, ['date']);
    //    }
    //    if (value == 5) {
    //        tabla3 = _.sortBy(tabla3, ['status']);
    //    }
    //    if (value == 6) {
    //        tabla3 = _.sortBy(tabla3, ['Mount']);
    //    }


    //    ///// refrescar la tabla
    //    tabla = tabla3;
    //    tabla2 = [];
    //    var auxTabla = [];




    //    let np = Math.ceil(tabla3.length / this.state.tamFilas);
    //    let restan = (this.state.tamFilas - (tabla3.length - ((np - 1) * this.state.tamFilas)));


    //    ///// LLenar los espacios de la tabla extra para que siempre sean 15 lineas
    //    if (tabla3.length % this.state.tamFilas < this.state.tamFilas)
    //        for (let j = 0; j < restan; j++) {
    //            const vacia2 = {
    //                Mount: "", status: "", date: "",
    //                cellExp: <Table.Cell singleLine>  </Table.Cell>,
    //                cellClient: <Table.Cell singleLine>  </Table.Cell>,}
    //            tabla3.push(vacia2)

    //        }

    //    this.setState({
    //        extraVoid: restan,
    //        //tipoB: value,
    //        pays: tabla3,
    //        open: false,
    //    }, () => {
    //        this.setState({
    //            open: false,
    //            contenido: this.TablaInicial(0),
    //            open: true,
    //        })
    //    })


    //}




    //eliminar() {
    //    const data = { id: this.state.idModal, otro: "" };
    //    this.props.delAlumn(data).then(res => {
    //        if (res) {
    //            tabla.splice(this.state.indiceModal, 1);
    //            tabla2.splice(this.state.indiceModal, 1);
    //            const vacia = { alumno_nombre: "", alumno_apellidop: "", alumno_apellidom: "", id_Alumno: "" }
    //            tabla.push(vacia)

    //            const iconos = {
    //                status: "",
    //                editar: <i className="material-icons  white-text tiny valign-wrapper ">create</i>,
    //                eliminar: <Table.Cell textAlign="center"></Table.Cell>,
    //                asistencia: ""
    //            }
    //            tabla2.push(iconos);

    //            this.close();
    //        }
    //    })
    //}

    //Recargar() {
    //    console.log("SE SUPONE QUE DEBE REINICIAR");
    //    this.componentWillMount();
    //}


     handleChangePage = (event, newPage) => {
         this.Paginacion(newPage);
    };

    handleChangeRowsPerPage = (event) => {

        this.setState({ tamFilas: parseInt(event.target.value, 10) });
        // setRowsPerPage(parseInt(event.target.value, 10));
         this.Paginacion(0);
    };



    Paginacion = ( num) => {
        let pag = num;

        this.setState({
            open: false,
            pagN: pag,
        }, () => {
            this.setState({
                open: false,
                contenido: this.TablaInicial(pag),
                open: true,
            })
        })
    }


    TablaInicial = (pagina) => {
        let content = [];
        let styles = { fontSize: "12px" };
        const { classes } = this.props;
        const ordenOptions = [

            {
                key: 'Fecha',
                text: 'Ordenar por: Fecha',
                value: '4',
            },
            {
                key: 'Estatus',
                text: 'Ordenar por: Estatus',
                value: '5',
            },
            {
                key: 'Monto',
                text: 'Ordenar por: Monto',
                value: '6',
            },

        ]

        content.push(
            <div className={classes.tableResponsive}>
                {/*<div>
                    <div  style={{ marginBottom: "0px", paddingBottom: "0px" }}>
                        <Dropdown
                            placeholder='Ordenar por:'
                            fluid
                            selection
                            id="tipoB"
                            onChange={this.Ordenar}
                            //value={this.state.tipoB}
                            options={ordenOptions}
                        />
                    </div>
                </div>*/}

                <Table size="small" className={classes.table} >
                    <TableHead className={classes["danger" + "TableHeader"]}>
                        <TableRow className={classes.tableHeadRow}>

                            <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>Monto</TableCell>
                            <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>Fecha</TableCell>
                            <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>Estatus</TableCell>

                        </TableRow>
                    </TableHead>


                        {this.createTable(pagina)}


                </Table>
                <TablePagination
                    rowsPerPageOptions={this.state.TamFilasArray}
                    component="div"
                    count={this.state.pays.length}
                    rowsPerPage={this.state.tamFilas}
                    page={this.state.pagN}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}

                />
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={this.state.open}

                    keepMounted
                    onClose={this.close}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description"
                >

                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                    >Realizar Pago</DialogTitle>
                    <DialogContent
                        id="modal-slide-description"
                        className={classes.modalBody}
                    >
                        id del pago:{this.state.idModal}


                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter + " " + classes.modalFooterCenter}
                    >

                        <Button  onClick={this.close} >Cancelar</Button>
                        <Button  />
                    </DialogActions>
                </Dialog>

            </div>
        )
        return content;
    }
    createTable = (pagina) => {
        let styles = { fontSize: "10px", };
        var i = 0;
        let filas = [];
        var np = pagina * this.state.tamFilas;
        //console.log(this.state.users);
        for (i = np; i < (np + this.state.tamFilas); i++) {
            // console.debug("indice: "+i);
          //const nombre = tabla[i].alumno_nombre + " " + tabla[i].alumno_apellidop + " " + tabla[i].alumno_apellidom;
            var colorLuz = "white";
            var Hoverable = false;
            const id = this.state.pays[i].uidCampain;
            console.log(this.state.pays[i].uidCampain);
            switch (this.state.pays[i].status) {
                case "Pending":
                    colorLuz = " yellow ";
                    Hoverable = true;
                    break;
                case "Paid":
                    colorLuz = " green ";
                    Hoverable = true;
                    break;
                case "Delayed":
                    colorLuz = " red ";
                    Hoverable = true;
                    break;
                case "":
                    colorLuz = "white";
                    break;

            }

            var cab = Hoverable ?
                <TableRow hover onClick={(event) => this.show(event, id,true)} >
                    <TableCell singleLine>{this.state.pays[i].Mount}</TableCell>
                    <TableCell singleLine>{this.state.pays[i].Date}</TableCell>
                    <TableCell singleLine><Lens style={{ color: colorLuz }} /></TableCell>
                </TableRow >:
                <TableRow  >
                    <TableCell singleLine>{this.state.pays[i].Mount}</TableCell>
                    <TableCell singleLine>{this.state.pays[i].Date}</TableCell>
                    <TableCell singleLine><Lens style={{ color: colorLuz }} /></TableCell>
                </TableRow >;


            filas.push(
                <TableBody>
                    {cab}
                </TableBody>
            )
        }
        return filas;

    }




    render() {

        return (
            <div>

                {this.state.contenido}
            </div>
        )
    }



}



const condition = authUser =>
    authUser && authUser.rol == (authUser.rol == ROLES.ADMIN || authUser.rol == ROLES.BRAND);//[ROLES.ADMIN];
export default compose(
    withStyles(styles),
    withFirebase)
    (TablePays);
