import React, { Component } from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import *as ROUTES from '../../constants/routes';

import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import * as ROLES from '../../constants/roles';
import ChartistGraph from "react-chartist";
import Tooltip from "@material-ui/core/Tooltip";

//import DatePicker from "react-datepicker";
//import { Dropdown } from 'semantic-ui-react';

import _ from 'lodash'
//import { Icon } from 'semantic-ui-react';
//import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'moment/locale/es';
import IconButton from '@material-ui/core/IconButton';
import imgExp from './img/expImg.png';
import imgLand from './img/landImg.png';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";


import Primary from "components/Typography/Primary.js";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import InputLabel from "@material-ui/core/InputLabel";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from '@material-ui/core/CircularProgress';

import Select from '@material-ui/core/Select';
import Icon from '@material-ui/core/Icon';

import CardActionArea from '@material-ui/core/CardActionArea';
import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";

import Edit from '@material-ui/icons/Edit';
import Lens from "@material-ui/icons/Lens";
import { cardTitle } from "assets/jss/material-kit-react.js";

import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from '@material-ui/core/styles';



var Chartist = require("chartist");
var delays = 80,
    durations = 500;
var delays2 = 80,
    durations2 = 500;

const styles = {
    cardTitle,
    ...imagesStyles,
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    textCenter: {
        textAlign: "center"
    },
    textRight: {
        textAlign: "right"
    }

};


const INITIAL_STATE = {
    loading: false,
    campData: {},
    expName: "",
    link: "",
    localization: "",
    status: "",
    statusPay: "",
    targetProfile: [{ 1: "" }, { 2: "" }],
    TokensMount: "",
    TokensType: "",
    GameSRC:"",
    loadGameImg: false,
    existLand:false,


    linkGame: "",
    statusGame: "En proceso",

    nCouponsRest:"",
    nGames:"",
    highsWeek: { altoPlays: 40, altoWins: 40, altoCoupons: 40, },
    playsWeek: [],
    daysWeek: [],
    winsWeek:[],
    couponsWeek: [],

    locals: [{ key: 0, text: "Mexico", value: "0" }, { key: 1, text: "EE.UU.", value: "1" }, { key: 2, text: "Canada", value: "2" }],
};

class PerfilCamp extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            ...INITIAL_STATE
        };
    }
    componentDidMount() {
        this.setState({ loading: true });
        let id=0;
        let idCamp=0 ;
        if (!this.props.id) { id = this.props.location.state.id; }
        else  id = this.props.id;
        if (!this.props.idCamp) idCamp = this.props.location.state.idCamp;
        else idCamp = this.props.idCamp;
        if (!this.props.nameClient) { this.setState({ nameCliente: this.props.location.state.nameClient }) }
        else this.setState({ nameCliente: this.props.nameClient })
        console.log("id: " + id);
        console.log("idCamp: " + idCamp);

        this.props.firebase.campain(id, idCamp).on('value', snapshot => {
            // this.props.firebase.users().on('value', snapshot => {
            const usersObject = snapshot.val();
            console.log(usersObject);

            var startD = new Date(usersObject.startDate.Year, usersObject.startDate.Month, usersObject.startDate.Day);
            var endD = new Date(usersObject.endDate.Year, usersObject.endDate.Month, usersObject.endDate.Day);
            var editD = new Date(usersObject.editDate.Year, usersObject.editDate.Month, usersObject.editDate.Day);
            //var arr = [];
            //Object.keys(usersObject.targetProfile).forEach(function (key) {
            //    arr.push(usersObject.targetProfile[key]);
            //});
            console.log(usersObject.targetProfile);


            //this.props.firebase.getImagBrand0(id, idCamp, usersObject.branding.file0)
            //    .then((downloadURL) => {
            //        console.log(downloadURL);
            //        this.setState({ GameSRC: downloadURL });
            //    }).catch( e =>{
            //      this.props.firebase.experience(usersObject.uidExp).on('value', snapshot => {
            //        const gameExp = snapshot.val();
            //        this.props.firebase.getGameImg(gameExp.image).then((downloadURL)=> {
            //            console.log("url : " + downloadURL);
            //            this.setState({ GameSRC: downloadURL });
            //        });
            //      })
            //    });
            //this.props.firebase.getImagBrand0(id, idCamp, usersObject.branding.land0)
            //    .then((downloadURL) => {
            //        console.log(downloadURL);
            //        if (downloadURL)
            //        this.setState({ LandSRC: downloadURL, existLand:true });

            //    });
            this.props.firebase.experienceLink(usersObject.uidExp).on(
                'value', snapshot => {
                    // this.props.firebase.users().on('value', snapshot => {
                    const link = snapshot.val();
                    console.log(link);

                    this.setState({
                        statusGame: "Juega Ahora",
                        linkGame: link,
                    })
            })
            this.props.firebase.experiencePlayTotal(usersObject.uidExp).on(
                'value', snapshot => {
                    console.log(snapshot.val());
                    this.setState({
                        nGames: snapshot.val(),
                    })
            })
            this.props.firebase.experienceNCouponsTotal(usersObject.uidExp).on(
                'value', snapshot => {
                    /*
                    console.log(snapshot.val());
                    this.setState({
                        nCouponsRest: snapshot.val(),
                    })
                    */
                })
            
            this.props.firebase.totalTokens().get().then(snapshot=>{
                    var i = 0;
                    var f = 0;
                    let token = [{"20": 0, "50": 0, "100": 0}, {"20": 0, "50": 0, "100": 0}];
                    snapshot.forEach((doc) => {
                        let id = doc.id;
                        let data = doc.data();
                        i+=data["value"];
                        ++f;
                        if(data["used"]!="") ++token[0][data["value"].toString()];
                            ++token[1][data["value"].toString()];

                    });
                    this.setState({
                        TokensType: "20: "+token[0]["20"]+"/"+token[1]["20"]+
                        ", 50: "+token[0]["50"]+"/"+token[1]["50"]+
                        ", 100: "+token[0]["100"]+"/"+token[1]["100"],
                        TokensMount: f
                    })
                    
                })

            this.props.firebase.experienceStatsMonth(usersObject.uidExp).on(
                'value', snapshot => {


                    const playObject = snapshot.val();
                    if (playObject) {
                        const playsWeek = Object.keys(playObject).map(key => ({
                            plays: playObject[key].nGames,
                            wins: playObject[key].nWins,
                            coupons: playObject[key].nCoupons,
                            day: key,

                        }));
                        //playsWeek.push({ plays: 2, wins: 3, coupons: 1, day: 2 })
                        //let fDay = playsWeek[0].day;
                        //var week = [];
                        //let extra = 7 - playsWeek.length;
                        //if (extra < 0) extra = 0;
                        //for (let i = 0; i < extra; i++) {
                        //
                        //}
                        //for (let i = 0; i < playsWeek.length; i++) {
                        //    week.push(playsWeek[i]);
                        //}
                        //console.log(week)
                        var tabla3 = [];
                        tabla3 = _.compact(playsWeek);
                        tabla3 = _.sortBy(tabla3, ['plays']);
                        let altoPlays = tabla3[tabla3.length - 1].plays + Math.ceil(tabla3[tabla3.length - 1].plays / 10);
                        tabla3 = _.sortBy(tabla3, ['wins']);
                        let altoWins = tabla3[tabla3.length - 1].wins + Math.ceil(tabla3[tabla3.length - 1].wins / 10);
                        tabla3 = _.sortBy(tabla3, ['coupons']);
                        let altoCoupons = tabla3[tabla3.length - 1].coupons + Math.ceil(tabla3[tabla3.length - 1].coupons / 10);

                        let alto = { altoPlays: altoPlays, altoWins: altoWins, altoCoupons: altoCoupons, }

                        var i = 0;
                        let plays = [];
                        let days = [];
                        let wins = [];
                        let cou = [];


                        for (i = 0; i < playsWeek.length; i++) {
                            plays[i] = playsWeek[i].plays;
                            days[i] = playsWeek[i].day;
                            wins[i] = playsWeek[i].wins;
                            cou[i] = playsWeek[i].coupons;
                        }


                        console.log(alto);
                        this.setState({
                            playsWeek: [plays],
                            daysWeek: days,
                            winsWeek: [wins],
                            couponsWeek: [cou],
                            highsWeek: alto
                        })
                    }
                })

            this.setState({
                campData: usersObject,
                startDate: startD,
                startDateS: { Year: usersObject.startDate.Year ,Month: usersObject.startDate.Month,Day: usersObject.startDate.Day},
                endDate: endD,
                endDateS: { Year: usersObject.endDate.Year, Month: usersObject.endDate.Month, Day: usersObject.endDate.Day },
                editDate: editD,
                editDateS: { Year: usersObject.editDate.Year, Month: usersObject.editDate.Month, Day: usersObject.editDate.Day },

                expName: usersObject.expName,
                link: usersObject.link,
                localization: usersObject.localization,
                status: usersObject.status,
                statusPay: usersObject.statusPay,
                targetProfile: usersObject.targetProfile,
                TokensMount: usersObject.TokensMount,
                TokensType: usersObject.TokensType,
                uid: id,
                idCamp:idCamp,
                loading: false,
            });
        });
    }

    componentWillUnmount() {
        this.props.firebase.users().off();
        this.props.firebase.experiencePlayToday().off();
        this.props.firebase.experienceNCouponsTotal().off();
    }


    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    }


    _onLoadGame = () => {
        this.setState(() => ({ loadGameImg: true }))
    }


    onUpdateCampain = (event ) => {
        //var profile = { this.state.rangeAgeFloor, this.state.rangeAgeCeil, this.state.gender };
        var editD = new Date();
        this.props.firebase.campain(this.props.id, this.props.idCamp).update({

            startDate: { Year: this.state.startDateS.Y, Month: this.state.startDateS.M, Day: this.state.startDateS.D, },
            endDate: { Year: this.state.endDateS.Y, Month: this.state.endDateS.M, Day: this.state.endDateS.D, },
            editDate: { Year: editD.getFullYear(), Month: editD.getMonth(), Day: editD.getDate(), },
            targetProfile: this.state.targetProfile,


            link: this.state.link,
            localization: this.state.localization,
            status: this.state.status,
            statusPay: this.state.statusPay,

        }).then(() => {
            this.setState({ ...INITIAL_STATE });

            this.props.history.push(ROUTES.CLIENTES);

        })
            .catch(error => {
                this.setState({ error });
            });;

        event.preventDefault();
    };

    addCamp = () => {
        const n = "newCamp" + Object.keys(this.state.targetProfile).length;

        const value = { ...this.state.targetProfile, [n]: 0 };
        this.setState({ targetProfile: value, });
    }

    localChange = (e, { value }) => {
        this.setState({ localization: value }

        );
    }

    handleChange(date) {

        this.setState({
            endDate: date,
            endDateS: { Y: date.getFullYear(), M: date.getMonth(), D: date.getDate()}


        })
    }

    render() {
        const { users, loading, playsWeek, couponsWeek, winsWeek, daysWeek, highsWeek } = this.state;
        const palabraCamp = "Campaña";

        moment.locale('es');




        const dailySalesChart = {

            data: {
                labels: daysWeek,
                series: playsWeek,
            },
            data2: {
                labels: daysWeek,
                series: winsWeek,
            },
            data3: {
                labels: daysWeek,
                series: couponsWeek,
            },

            options: {
                lineSmooth: Chartist.Interpolation.cardinal({
                    tension: 0
                }),
                low: 0,
                high: highsWeek.altoPlays, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
                chartPadding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                }
            },
            options2: {
                lineSmooth: Chartist.Interpolation.cardinal({
                    tension: 0
                }),
                low: 0,
                high: highsWeek.altoWins, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
                chartPadding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                }
            },
            options3: {
                lineSmooth: Chartist.Interpolation.cardinal({
                    tension: 0
                }),
                low: 0,
                high: highsWeek.altoCoupons, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
                chartPadding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                }
            },
            // for animation
            animation: {
                draw: function (data) {
                    if (data.type === "line" || data.type === "area") {
                        data.element.animate({
                            d: {
                                begin: 600,
                                dur: 700,
                                from: data.path
                                    .clone()
                                    .scale(1, 0)
                                    .translate(0, data.chartRect.height())
                                    .stringify(),
                                to: data.path.clone().stringify(),
                                easing: Chartist.Svg.Easing.easeOutQuint
                            }
                        });
                    } else if (data.type === "point") {
                        data.element.animate({
                            opacity: {
                                begin: (data.index + 1) * delays,
                                dur: durations,
                                from: 0,
                                to: 1,
                                easing: "ease"
                            }
                        });
                    }
                }
            }
        };


        const profile = [];
        let colorStatus = "yellow";
        switch (this.state.status) {
            case "Waiting":
                colorStatus = "yellow";
                break;
            case "Active":
                colorStatus = "green";
                break;
            case "concluded":
                colorStatus = "red";
                break;

        }
        const { classes } = this.props;
        for (var clave in this.state.targetProfile) {
            // Controlando que json realmente tenga esa propiedad
            if (this.state.targetProfile.hasOwnProperty(clave)) {
                // Mostrando en pantalla la clave junto a su valor
              //md  console.log("La clave es " + clave + " y el valor es " + this.state.targetProfile[clave]);
                profile.push(

                    <GridItem xs={3} >

                        <CustomInput
                            labelText={clave}
                            id={clave}

                            value={this.state.targetProfile[clave]}
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                onChange: this.onChange,
                                value: this.state.targetProfile[clave],
                                disabled: true
                            }}
                        />

                    </GridItem>
                );
            }
        }
        return (
            <div>
            <Card >
                    <CardHeader color="danger">

                        <GridContainer direction="row"
                            justify="space-between"
                            alignItems="center">
                            <GridItem xs={8} >
                                <h1 className={classes.cardTitleWhite}>Detalles de campa&ntilde;a</h1>
                            </GridItem>
                            <GridItem xs={2} >
                                <div className={classes.textRight}>
                                    <Tooltip
                                        id="tooltip-left"
                                        title="Editar"
                                        placement="top"
                                        classes={{ tooltip: classes.tooltip }}
                                    >
                                        <IconButton aria-label="add to favorites">
                                            <Edit fontSize="large" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </GridItem>
                        </GridContainer>



                </CardHeader>
                <form >
                    <CardBody>

                {loading && <div>Loading ...</div>}

                    <GridContainer direction="row"
                        justify="space-between"
                        alignItems="center">
                        <GridItem  xs={12} sm={6} md={2} >


                                <h3>{this.state.nameCliente}</h3>
                                <h5>{this.state.campData.expName}</h5>

                        </GridItem>
                        <GridItem  xs={12} sm={12} md={4}>



                                <GridContainer direction="row"
                                    justify="space-around"
                                    alignItems="center">
                                    <GridItem xs={12} sm={6} md={6}>
                                            <InputLabel className={classes.label}>
                                                Fecha de inicio {moment(this.state.startDate).fromNow()}
                                        </InputLabel>
                                        <br />
                                        <FormControl fullWidth>
                                                <Datetime
                                                value={this.state.startDate}
                                                    inputProps={{ placeholder: "Datetime Picker Here", disabled: true  }}
                                                onChange={this.handleChange}
                                            />
                                        </FormControl>
                                    </GridItem>
                                        <GridItem xs={12} sm={6} md={6}>
                                            <InputLabel className={classes.label}>
                                                Fecha de termino {moment(this.state.endDate).fromNow()}
                                        </InputLabel>
                                        <br />
                                        <FormControl fullWidth>
                                            <Datetime
                                                value={this.state.endDate}
                                                    inputProps={{ placeholder: "Datetime Picker Here", disabled: true  }}
                                                onChange={this.handleChange}
                                            />
                                        </FormControl>
                                    </GridItem>
                                </GridContainer>

                        </GridItem>
                        <GridItem xs={12} sm={6} md={2}>


                                <InputLabel className={classes.label}>
                                        Ultima edicion {moment(this.state.editDate).fromNow()}
                                </InputLabel>
                                <br />
                                <FormControl fullWidth>
                                        <Datetime
                                            value={this.state.editDate}
                                        inputProps={{ placeholder: "Datetime Picker Here" , disabled: true }}
                                        onChange={this.handleChange}
                                    />
                                </FormControl>

                        </GridItem>
                         <GridItem xs={12} sm={6} md={2}>
                                <GridContainer direction="row"
                                    justify="space-around"
                                    alignItems="flex-start">
                                    <GridItem xs={2}><Lens style={{ color: colorStatus }} /></GridItem>
                                    <GridItem xs={10}>{this.state.status}</GridItem>
                                </GridContainer>


                                </GridItem>
                                {/*
                         <GridItem xs={12} sm={12} md={12} lg={12}>


                                    <GridContainer direction="row-reverse"
                                        justify="flex-start"
                                        alignItems="flex-start">

                                        <GridItem GridItem xs={4} sm={3} md={2} lg={1} xl={1}><Button color="primary" onClick={event => this.onUpdateCampain(event)} >Publicar </Button>  </GridItem>
                                    </GridContainer>


                         </GridItem>
                         */}
                    </GridContainer>

                    <GridContainer direction="row"
                        justify="space-around"
                        alignItems="stretch">
                            <GridContainer direction="row"
                                justify="space-around"
                                    alignItems="center" xs={12} sm={12} md={6}>
                                    <GridItem xs={12} sm={6}>

                                    <Card className={classes.textCenter}>
                                        <CardActionArea  style={{ padding: "20px"  }}>
                                                <a id="linkJuego" href={this.state.link} target="_blank" >
                                                    <img className={classes.imgCardTop} src={"/mcLogo.png"/*this.state.GameSRC*/} onLoad={this._onLoadGame} style={{ display: this.state.loadGameImg }} />

                                                {!this.state.loadGameImg &&
                                                <GridContainer direction="row"
                                                        justify="center"
                                                        alignItems="stretch" >
                                                    <GridItem xs={12}>
                                                        <CircularProgress color="danger" size={"100%"} />
                                                    </GridItem>
                                                </GridContainer>
                                                }
                                                    <CardBody>
                                                        <h4 className={classes.cardTitle}>{this.state.statusGame}</h4>

                                                    </CardBody>
                                                    </a>
                                        </CardActionArea  >
                                    </Card>


                                    </GridItem>
                                    {/*
                                    <GridItem xs={12} sm={6}>


                                    <Card className={classes.textCenter} >
                                            <CardActionArea  style={{ padding: "20px"}}>


                                                <Link to={{
                                                    pathname: ROUTES.LANDING_GAME,
                                                    state: {
                                                        idCliente: this.state.uid, idCamp: this.state.idCamp
                                                    }

                                                }}>

                                                    {this.state.existLand ?
                                                        <img className={classes.imgCardTop} src={this.state.LandSRC} style={{ display: this.state.loadGameImg }} />
                                                        :
                                                        <img className={classes.imgCardTop} src={imgLand} />
                                                    }


                                                    <CardBody>
                                                        <h4 className={classes.cardTitle}>Ver Landing page</h4>

                                                    </CardBody>
                                                </Link>
                                        </CardActionArea >
                                    </Card>


                                </GridItem>
                    */}
                            </GridContainer>
                            <GridContainer direction="column"
                                justify="space-between"
                                alignItems="stretch" xs={12}  sm={12} md={6}>

                                <GridContainer>
                                        <GridItem xs={12} sm={6}>

                                                <CustomInput
                                                    labelText="Localizacion"
                                                    id="localization"
                                                    value={this.state.localization}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        onChange: this.onChange,
                                                        value: this.state.localization,
                                                        disabled: true
                                                    }}
                                                />

                                    </GridItem>
                                        <GridItem xs={12} sm={6}>

                                        <CustomInput
                                            labelText="Link Landing page"
                                            id="link"

                                            value={this.state.link}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: this.onChange,
                                                value: this.state.link,
                                                disabled: true
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>

                                <GridContainer ><h3>Perfil del jugador</h3></GridContainer>

                                <GridContainer >
                                {profile}
                                        {/*
                                    <GridItem xs={3} >

                                            <Button color="primary" onClick={this.addCamp} >+Agregar </Button>

                                    </GridItem>
                                    */}
                                </GridContainer>

                                <GridContainer >
                                    <GridItem xs={6}>

                                            {/*  <Dropdown
                                                placeholder='Selecciona Tipo'
                                                fluid
                                                selection
                                                options={this.state.paisOptions}
                                                id="pais"
                                                onChange={this.paisChange}
                                                value={this.state.pais}

                                            />*/}


                                        <CustomInput
                                            labelText="Tipo de tokens"
                                            id="TokensType"

                                            value={this.state.TokensType}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: this.onChange,
                                                value: this.state.TokensType,
                                                disabled: true
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={6}>
                                            {/*<Dropdown
                                                placeholder='Selecciona Cantidad'
                                                fluid
                                                selection
                                                options={this.state.paisOptions}
                                                id="pais"
                                                onChange={this.paisChange}
                                                value={this.state.pais}

                                            />*/}

                                        <CustomInput
                                            labelText="Cantidad de tokens"
                                            id="TokensMount"

                                            value={this.state.TokensMount}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: this.onChange,
                                                value: this.state.TokensMount,
                                                disabled: true
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>

                            </GridContainer>

                        </GridContainer>
                    </CardBody>
                </form >
                </Card>
                <Primary> <h1><b>Estadisticas del &uacute;ltimo mes</b></h1></Primary>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <Card className={classes.textCenter}>
                            <CardActionArea style={{ padding: "20px" }}>
                                <a id="linkJuego" /*href={this.state.link} target="_blank"*/ >
                                    <img className={classes.imgCardTop} src={"/affinity.png"} onLoad={this._onLoadGame} style={{ display: this.state.loadGameImg }} />
                                    {!this.state.loadGameImg &&
                                        <GridContainer direction="row"
                                            justify="center"
                                            alignItems="stretch" >
                                            <GridItem xs={12}>
                                                <CircularProgress color="danger" size={"100%"} />
                                            </GridItem>
                                        </GridContainer>
                                    }
                                    <CardBody>
                                        <h4 className={classes.cardTitle}>Afinidades de Usuario</h4>
                                    </CardBody>
                                </a>
                            </CardActionArea  >
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <Card className={classes.textCenter}>
                            <CardActionArea style={{ padding: "20px" }}>
                                <a id="linkJuego" /*href={this.state.link} target="_blank"*/ >
                                    <img className={classes.imgCardTop} src={"/active.png"} onLoad={this._onLoadGame} style={{ display: this.state.loadGameImg }} />
                                    {!this.state.loadGameImg &&
                                        <GridContainer direction="row"
                                            justify="center"
                                            alignItems="stretch" >
                                            <GridItem xs={12}>
                                                <CircularProgress color="danger" size={"100%"} />
                                            </GridItem>
                                        </GridContainer>
                                    }
                                    <CardBody>
                                        <h4 className={classes.cardTitle}>Usuarios Activos</h4>
                                    </CardBody>
                                </a>
                            </CardActionArea  >
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <Card className={classes.textCenter}>
                            <CardActionArea style={{ padding: "20px" }}>
                                <a id="linkJuego" /*href={this.state.link} target="_blank"*/ >
                                    <img className={classes.imgCardTop} src={"/demographics.png"} onLoad={this._onLoadGame} style={{ display: this.state.loadGameImg }} />
                                    {!this.state.loadGameImg &&
                                        <GridContainer direction="row"
                                            justify="center"
                                            alignItems="stretch" >
                                            <GridItem xs={12}>
                                                <CircularProgress color="danger" size={"100%"} />
                                            </GridItem>
                                        </GridContainer>
                                    }
                                    <CardBody>
                                        <h4 className={classes.cardTitle}>Demografía de Usuario</h4>
                                    </CardBody>
                                </a>
                            </CardActionArea  >
                        </Card>
                    </GridItem>
                    
                    
                </GridContainer>
                </div>
        );
    }
}
/*

                    <GridItem xs={12} sm={12} md={4}>

                        <Card chart>
                            <CardHeader color="success" >
                                <ChartistGraph
                                    className="ct-chart"
                                    data={dailySalesChart.data}
                                    type="Line"
                                    options={dailySalesChart.options}
                                    listener={dailySalesChart.animation}
                                />
                            </CardHeader>
                            <CardBody>
                                <h4 className={classes.cardTitle}>Reproducciones Diarias</h4>
                                <p className={classes.cardCategory}>
                                    <span className={classes.successText}>
                                        {this.state.nGames}
                                    </span>{" "}
                                    Partidas Totales Jugadas
                                </p>
                            </CardBody>
                            <CardFooter chart>
                                <div className={classes.stats}>

                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <Card chart>
                            <CardHeader color="info" >
                                <ChartistGraph
                                    className="ct-chart"
                                    data={dailySalesChart.data2}
                                    type="Line"
                                    options={dailySalesChart.options2}
                                    listener={dailySalesChart.animation}
                                />
                            </CardHeader>
                            <CardBody>
                                <h4 className={classes.cardTitle}>Jugadores que ganaron</h4>
                                <p className={classes.cardCategory}>
                                    <span className={classes.successText}>
                                        55%
                                </span>{" "}
                                    Aumento del impacto.
                                </p>
                            </CardBody>
                            <CardFooter chart>
                                <div className={classes.stats}>

                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <Card chart>
                            <CardHeader color="warning" >
                                <ChartistGraph
                                    className="ct-chart"
                                    data={dailySalesChart.data3}
                                    type="Line"
                                    options={dailySalesChart.options3}
                                    listener={dailySalesChart.animation}
                                />
                            </CardHeader>
                            <CardBody>
                                <h4 className={classes.cardTitle}>Premios entregados</h4>
                                <p className={classes.cardCategory}>
                                    <span className={classes.successText}>
                                        {this.state.nCouponsRest}
                                </span>{" "}
                                    Premios restantes.
                                </p>
                            </CardBody>
                            <CardFooter chart>
                                <div className={classes.stats}>

                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>
*/

const condition = authUser =>
    authUser && (authUser.rol == ROLES.ADMIN || authUser.rol == ROLES.BRAND);//[ROLES.ADMIN];
export default compose(
    withAuthorization(condition),
    withStyles(styles),
    withFirebase)
    (PerfilCamp);
