import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import  Navigation  from '../Navigation';
import Header from '../Navigation/Header';
import Navbar from '../Navigation/Navbar';
import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import HomeStadistics from '../HomeStadistics';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import GClientesPage from '../Clientes/Clientes';

import RClientes from '../Clientes/RegistarClientes';
import PerfilCamp from '../Clientes/PerfilCamp';
import Campains from '../Campains/Campains';
import RCampains from '../Campains/RegistarCampain';
import ClienteDash from '../Clientes/ClientesDash';
import ActiveCampain from '../Clientes/ActiveCampain';
import ProfileClient from "../Clientes/ClientProfile";
import RTarget from "../Target/RegistarTarget";
import SingInTarget from "../Target/SignInTarget"
import exchCoupon from "../Target/ActiveCup"
import LandingGame from "../Target/LandingGame"
import Pays from "../Clientes/Pays";

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import { AuthUserContext } from '../Session';


////////////// tim creative//////////////////////
import "assets/scss/material-kit-react.scss?v=1.8.0";
import "assets/css/material-dashboard-react.css?v=1.8.0";
import Container from '@material-ui/core/Container';

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
// pages for this product
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#f55a4e"
    }
  },
});


//////////////////////////////////////////////////
const mainPanel = React.createRef();
const useStyles = makeStyles(styles);
function App(props) {
    const classes = useStyles();
    return (
      <ThemeProvider theme={theme}>
        <Router>

            <div className={classes.wrapper}>


                <Navigation/>

                <div className={props.authUser != null? classes.mainPanel : ""} ref={mainPanel}>

                    <div className={classes.content} style = {{ padding: "0px 70px"}}>
                        <div className={classes.container}>
                            <Route exact path={ROUTES.LANDING} component={LandingPage} />

                            <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
                            <Route path={ROUTES.SIGN_IN} component={SignInPage} />
                            <Route
                                path={ROUTES.PASSWORD_FORGET}
                                component={PasswordForgetPage}
                            />
                            <AuthUserContext.Consumer>
                                {authUser => (
                                    <div>
                                    <Route
                                        path={ROUTES.CLIENTEDASH}
                                        render={(props) => <ClienteDash {...props} idC={authUser} />}
                                    />
                                    <Route
                                        path={ROUTES.PROFILECLIENT}
                                        render={(props) => <ProfileClient {...props} idC={authUser}/>}
                                        />
                                    </div>
                                    )
                                }
                            </AuthUserContext.Consumer>

                            <Route exact path={ROUTES.HOME} component={HomePage} />
                            <Route exact path={ROUTES.HOME+"/:id"} component={HomeStadistics} />
                            <Route path={ROUTES.ACCOUNT} component={AccountPage} />
                            <Route path={ROUTES.ADMIN} component={AdminPage} />
                            <Route path={ROUTES.CLIENTES} component={GClientesPage} />
                            <Route path={ROUTES.PAYSADMI} component={Pays} />
                            <Route path={ROUTES.REGISTROCLIENTES} component={RClientes} />
                            <Route path={ROUTES.CAMPAINS} component={Campains} />
                            <Route path={ROUTES.REGISTROCAMPAINS} component={RCampains} />

                            <Route path={ROUTES.ACTIVECAMPAIN} component={ActiveCampain} />

                            <Route path={ROUTES.PERFILCAMP} component={PerfilCamp} />
                            <Route path={ROUTES.REGISTROTARGET + "/:id/:token"} component={RTarget} />
                            <Route path={ROUTES.SIGN_IN_T + "/:id/:token"} component={SingInTarget} />
                            <Route path={ROUTES.EXCHANGE_C + "/:id/:token/:idUser"} component={exchCoupon} />
                            <Route path={ROUTES.LANDING_GAME} component={LandingGame} />
                        </div>

                    </div>
                </div>
            </div>
        </Router>
         </ThemeProvider>
        )
}


export default withAuthentication( App);
