import React from 'react';
import { withFirebase } from '../Firebase';
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import Person from "@material-ui/icons/Person";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

const SignOutButton = ({ firebase }) => (
    <button type="button" color="danger" onClick={firebase.doSignOut}>
        Sign Out
    </button>
);


function SignOutButton2({ firebase }) {
    const classes = useStyles();


    return (
    <Button
        color={window.innerWidth > 959 ? "transparent" : "danger"}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}

        aria-haspopup="true"
        onClick={firebase.doSignOut}
        className={classes.buttonLink}
    >
        <Person className={classes.icons} />
            <p onClick={firebase.doSignOut} className={classes.linkText}>
            Sign Out
                        </p>
        </Button>
        )
}
const BSignOut = withFirebase(SignOutButton2);

export { BSignOut };

export default withFirebase(SignOutButton);
