import React, { Component } from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import *as ROUTES from '../../constants/routes';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Primary from "components/Typography/Primary.js";
import Divider from '@material-ui/core/Divider';

import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import * as ROLES from '../../constants/roles';
import Button from 'components/CustomButtons/Button';

import CardCamp from './CardCamp';

import _ from 'lodash'



class Clientes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            campains: [],
        };
    }
    componentDidMount() {
        this.setState({ loading: true });
        this.props.firebase.experiences().on('value', snapshot => {
            const campainsObject = snapshot.val();
            const campainsList = Object.keys(campainsObject).map(key => ({
                ...campainsObject[key],
                uid: key,
            }));

            this.setState({
                campains: campainsList,
                loading: false,
            });
        });
    }

    componentWillUnmount() {
        this.props.firebase.experience().off();
    }
    render() {
        const { campains, loading } = this.state;
        return (

            <GridContainer
                direction="column"
                justify="space-around"
                alignItems="stretch"

            >
                <GridContainer >
                    <GridItem>
                        <Primary> <h1><b>Experiencias</b></h1></Primary>
                        <Divider variant="middle " />
                        {loading && <div>Loading ...</div>}
                    </GridItem>
                </GridContainer >
                <GridContainer

                    direction="row-reverse"
                    justify="flex-start"
                    alignItems="baseline"
                >

                    <GridItem xs={3}>

                        <Link to={ROUTES.REGISTROCAMPAINS}>
                            <Button type="button" size="lg" color="danger"> Agregar Experiencia</Button>
                        </Link>
                        <br /><br />
                    </GridItem>
                </GridContainer>

                <CampCards campains={campains} />

            </GridContainer>


        );
    }
}

const CampCards = ({ campains }) => (
    <GridContainer

        direction="row"
        justify="flex-start"
        alignItems="baseline"
    >

        {campains.map(campain => (
            <GridItem xs={12} sm={6} md={3}>
                < CardCamp id={campain.uid} />
            </GridItem>
        ))}
    </GridContainer>

);

const condition = authUser =>
    authUser && authUser.rol == ROLES.ADMIN;//[ROLES.ADMIN];
export default compose(
    withAuthorization(condition),
    withFirebase)
    (Clientes);
