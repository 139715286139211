import React, { Component } from 'react';
import { Link, withRouter, useParams } from 'react-router-dom';
//import { useParams } from "react-router";
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import { withStyles } from '@material-ui/core/styles';

import { withAuthorization } from '../Session';

import { SignInLink } from './SignInTarget'
import { PasswordForgetLink } from '../PasswordForget';


const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
};



function RegistarTarget() {

    let { id, token } = useParams();

    return (
        <div>
            <h3>ID:{id} TOKEN:{token}</h3>
            <RegistarTargetFormBase idCamp={id} token={token}/>
        </div>
    );

};

const INITIAL_STATE = {
    username: '',
    email: '',
    age: '',
    gender: '',
    passwordOne: '',
    passwordTwo: '',
    rol: ROLES.TARGET,
    error: null,
    coupons: {},

};


class RegistarTargetFormBase extends Component {

  constructor(props) {
      super(props);


      this.state = {
          ...INITIAL_STATE,

      }
  }
    onSubmit = event => {
        const { username, email, passwordOne, rol,age, gender } = this.state;
        var coupons = "";
        if (this.props.match.params.id && this.props.match.params.token) {
            coupons = { [this.props.match.params.id]: this.props.match.params.token }
        }
        var idUser = "";
        this.props.firebase
            .doCreateUserWithEmailAndPassword (email, passwordOne)
            .then(authUser => {
                // Create a user in your Firebase realtime database
                idUser = authUser.user.uid;
                return this.props.firebase
                    .user(authUser.user.uid)
                    .set({
                        username,
                        email,
                        rol,
                        age,
                        gender,

                    });

            })
            .then(() => {
                this.setState({ ...INITIAL_STATE });

                if (this.props.match.params.id && this.props.match.params.token) {
                    coupons = { [this.props.match.params.id]: this.props.match.params.token }

                    this.props.history.push(ROUTES.EXCHANGE_C + "/" + this.props.match.params.id + "/" + this.props.match.params.token + "/" + idUser);
                } else {
                    this.props.history.push(ROUTES.HOME);
                }

            })
            .catch(error => {
                this.setState({ error });
            });
        event.preventDefault();
  }
    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const {
            username,
            email,
            passwordOne,
            passwordTwo,
            error,
            age,
            gender,
            rol,
        } = this.state;

        const isInvalid =
            passwordOne !== passwordTwo ||
            passwordOne === '' ||
            email === '' ||
            username === '';
        const { classes } = this.props;

        return (

             <form onSubmit={this.onSubmit}>
                <GridContainer xs={12} sm={10} md={8}>
                    <Card >
                        <CardHeader>
                          <CardIcon color="danger">
                            <h4 className={classes.cardTitleWhite}>Registro de Jugadores</h4>
                            <p className={classes.cardCategoryWhite}>Registra los datos</p>
                          </CardIcon>
                        </CardHeader>

                        <CardBody>
                            <GridContainer container
                                direction="row"
                                justify="space-around"
                                alignItems="center" xs={12}>

                                <GridItem>
                                    <CustomInput
                                        labelText="Nombre de usuario"
                                        value={username}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            onChange: this.onChange,
                                            type: "text",
                                            name: "username"
                                        }}
                                    />
                                </GridItem>

                                <GridItem>
                                    <CustomInput
                                        labelText="Direccion de correo"
                                        value={email}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            onChange: this.onChange,
                                            type: "text",
                                            name: "email"
                                        }}
                                    />
                                </GridItem>
                                <GridItem>
                                    <CustomInput
                                        labelText="Edad"
                                        value={age}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            onChange: this.onChange,
                                            type: "text",
                                            name: "age"
                                        }}
                                    />
                                </GridItem>
                                <GridItem>
                                    <CustomInput
                                        labelText="Sexo"
                                        value={gender}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            onChange: this.onChange,
                                            type: "text",
                                            name: "gender"
                                        }}
                                    />
                                </GridItem>

                                <GridItem>
                                    <CustomInput
                                        labelText="Password"
                                        value={passwordOne}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            onChange: this.onChange,
                                            type: "text",
                                            name: "passwordOne"
                                        }}
                                    />
                                </GridItem>

                                <GridItem>
                                    <CustomInput
                                        labelText="Confirmar password"
                                        value={passwordTwo}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            onChange: this.onChange,
                                            type: "text",
                                            name: "passwordTwo"
                                        }}
                                    />
                                </GridItem>

                                <Button type="submit" color="danger">
                                    Registrar
                                </Button>
                                {error && <p>{error.message}</p>}
                            </GridContainer>
                        </CardBody>
                    </Card>
                    <p>
                        <Link to={ROUTES.SIGN_IN_T + "/" + this.props.match.params.id + "/" + this.props.match.params.token}>¿Ya estas registrado?</Link>
                    </p>
                </GridContainer>

      </form>

                );
  }
}
const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Regitrar</Link>
  </p>
);



export default compose(
    withRouter,
    withFirebase,
    withStyles(styles),
)(RegistarTargetFormBase);


//export default RegistarTarget;
//export { RegistarTargetForm, SignUpLink };
