import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

const config = {
    apiKey: "AIzaSyC8IEGl6OScagjnIAaS_VquOAsmaBKyd7M",
    authDomain: "mccormick-slasher.firebaseapp.com",
    databaseURL: "https://mccormick-slasher-default-rtdb.firebaseio.com",
    projectId: "mccormick-slasher",
    storageBucket: "mccormick-slasher.appspot.com",
    messagingSenderId: "928597714644",
    appId: "1:928597714644:web:69c33d277c8aa0290d3d41",
    measurementId: "G-8HRSVVT7RG"
  };

class Firebase {
    constructor() {
        app.initializeApp(config);
        this.state = {
            DateToday:new Date() ,
            //users: [],
        };

        this.storageRef=app.storage().ref();
        this.auth = app.auth();
        this.db = app.database();
        this.fstore = app.firestore();


        this.secondaryApp = app.initializeApp(config, "Secondary");
    }

    // *** Auth API ***
    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);


    doCreateUserSecundary = (email, password) =>
        this.secondaryApp.auth().createUserWithEmailAndPassword(email, password);//.then(function(firebaseUser) {
    //        console.log("User " + firebaseUser.uid + " created successfully!");

    //        this.state.secondaryApp.auth().signOut();
    //});
    doSignOutSecundary = () => this.secondaryApp.auth().signOut();

    // *** Merge Auth and DB User API *** //
    onAuthUserListener = (next, fallback) => this.auth.onAuthStateChanged(authUser => {
        if (authUser) {
            this.user(authUser.uid).once('value').then(snapshot => {
                const dbUser = snapshot.val();
                // default empty roles
                if (!dbUser.rol) { dbUser.rol = {}; }
                // merge auth and db user
                authUser = {
                    uid: authUser.uid,
                    email: authUser.email,
                    ...dbUser,
                };
                next(authUser);
            });
        } else { fallback(); }
    });

    ServerDateString = () => `${this.state.DateToday.getFullYear()} /${this.state.DateToday.getMonth() + 1}/${this.state.DateToday.getDate()}`;
    ServerFullYear = () => this.state.DateToday.getFullYear();
    ServerMonth = () => this.state.DateToday.getMonth();
    ServerDate = () => this.state.DateToday.getDate();


    user = uid => this.db.ref(process.env.REACT_APP_API_URL+`/users/${uid}`);
    users = () => this.db.ref(process.env.REACT_APP_API_URL + '/users');
    rol = uid => this.db.ref(process.env.REACT_APP_API_URL + `/users/${uid}/rol`);
    coupons = (uid, yid) => this.db.ref(process.env.REACT_APP_API_URL + `/users/${uid}/coupons/${yid}`);
    lastPlay = (uid, yid) => this.db.ref(process.env.REACT_APP_API_URL + `/users/${uid}/campPlay/${yid}/Plays`).orderByValue().limitToLast(1);
    coupons2 = (uid, yid,time) => this.db.ref(process.env.REACT_APP_API_URL + `/users/${uid}/campPlay/${yid}/Plays/${time}`);

    //games = (uid) => this.db.ref(process.env.REACT_APP_API_URL + `/games`);

    campain = (uid, yid) => this.db.ref(process.env.REACT_APP_API_URL + `/users/${uid}/campains/${yid}`);
    campains = (uid) => this.db.ref(process.env.REACT_APP_API_URL + `/users/${uid}/campains`);

    campainsClient = () => this.db.ref(process.env.REACT_APP_API_URL + '/campains');

    experience = uid => this.db.ref(process.env.REACT_APP_API_URL + `/experience/${uid}`);
    experienceLink = uid => this.db.ref(process.env.REACT_APP_API_URL + `/experience/${uid}/link`);
    experiences = () => this.db.ref(process.env.REACT_APP_API_URL + '/experience');

    experienceNCoupons = uid => this.db.ref(process.env.REACT_APP_API_URL + `/experience/${uid}/nCoupons`);

    // Demograficos--------------------------
    experiencePlayToday = uid => this.db.ref(process.env.REACT_APP_API_URL + `/experience/${uid}/stats/${this.state.DateToday.getFullYear()}/${this.state.DateToday.getMonth()+1}/${this.state.DateToday.getDate()}/nGames`);
    experienceNCouponsToday = uid => this.db.ref(process.env.REACT_APP_API_URL + `/experience/${uid}/stats/${this.state.DateToday.getFullYear()}/${this.state.DateToday.getMonth() + 1}/${this.state.DateToday.getDate()}/nCoupons`);
    experienceNCouponsTotal = uid => this.db.ref(process.env.REACT_APP_API_URL + `/experience/${uid}/nCoupons`);

    experienceStatsMonth = uid => this.db.ref(process.env.REACT_APP_API_URL + `/experience/${uid}/stats/${this.state.DateToday.getFullYear()}/${this.state.DateToday.getMonth() + 1}`);
    experiencePlayTotal = uid => this.db.ref(process.env.REACT_APP_API_URL + `/experience/${uid}/stats/nGamesTotal`);

    totalTokens= () => this.fstore.collection('tokens');//.get();//.where("used", "!=", "").get();

    // storage

    campaingResources = (uid) => this.storageRef.child(process.env.REACT_APP_API_URL + `/experience/${uid}/Resources`);
    campaingResourcesLand = (uid) => this.storageRef.child(process.env.REACT_APP_API_URL + `/experience/${uid}/ResourcesLand`);
    setGameImg = (img) => this.storageRef.child(process.env.REACT_APP_API_URL + `/games/${img}`)
    campainBranding = (uid, yid, name) => this.storageRef.child(process.env.REACT_APP_API_URL + `/users/${uid}/campains/${yid}/${name}`);

    getImagBrand0 = (uid, yid, name) => this.storageRef.child(process.env.REACT_APP_API_URL + `/users/${uid}/campains/${yid}/${name}`).getDownloadURL();

    getGameImg = (img) => this.storageRef.child(process.env.REACT_APP_API_URL + `/games/${img}`).getDownloadURL();

    downloadBrand = (uid) => this.storageRef.child(process.env.REACT_APP_API_URL + `/experience/${uid}/Resources.rar`).getDownloadURL();
    downloadBrandLand = (uid) => this.storageRef.child(process.env.REACT_APP_API_URL + `/experience/${uid}/ResourcesLand.rar`).getDownloadURL();
}
export default Firebase;
