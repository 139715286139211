import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';


import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";

import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import { withStyles } from '@material-ui/core/styles';
import * as ROUTES from '../../constants/routes';




const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
};


const SignInPage = () => (
    <div>

        <SignInForm />
        <PasswordForgetLink />
        {/* <SignUpLink />*/}
    </div>
);
const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
};
class SignInFormBase extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }
    onSubmit = event => {
        const { email, password } = this.state;
        this.props.firebase
            .doSignInWithEmailAndPassword(email, password)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                this.props.history.push(ROUTES.CLIENTEDASH);
            })
            .catch(error => {
                this.setState({ error });
            });
        event.preventDefault();
    };
    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    render() {
        const { email, password, error } = this.state;
        const isInvalid = password === '' || email === '';

        const { classes } = this.props;
        return (
            <form onSubmit={this.onSubmit}>
                <GridContainer xs={12} sm={10} md={8}>
                <Card >
                    <CardHeader>
                      <CardIcon color="danger">
                        <h4 className={classes.cardTitleWhite}>Inicio de Sesión</h4>
                        <p className={classes.cardCategoryWhite}>Ingresa tus datos</p>
                      </CardIcon>
                    </CardHeader>

                        <CardBody>
                        <GridContainer container
                            direction="row"
                            justify="space-around"
                            alignItems="center" xs={12}>


                            <GridItem>

                                <CustomInput
                                    labelText="Email Address"

                                    value={email}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: this.onChange,
                                        type: "text",
                                        name:"email"
                                    }}
                                />
                            </GridItem>
                            <GridItem>

                                <CustomInput
                                    labelText="password"
                                    value={password}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: this.onChange,
                                        type: "password",
                                        name:"password"
                                    }}
                                />
                                </GridItem>

                            <GridItem>
                                <Button disabled={isInvalid} color="danger"  type="submit">
                                        Sign In
                                </Button>
                                    {error && <p>{error.message}</p>}
                            </GridItem>
                            </GridContainer>
                    </CardBody>
                    </Card>
                </GridContainer>




            </form>
        );
    }
}
const SignInForm = compose(
    withStyles(styles),
    withRouter,
    withFirebase,
)(SignInFormBase);
export default SignInPage;
export { SignInForm };
