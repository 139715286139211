import React, { Component } from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import * as ROLES from '../../constants/roles';
import *as ROUTES from '../../constants/routes';

import Lens from "@material-ui/icons/Lens";



import Paper from '@material-ui/core/Paper';
import Table from "@material-ui/core/Table";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';

import _ from 'lodash'

import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import PerfilCamp from "./PerfilCamp";
import PerfilClient from "./ClientProfile";


var tabla = [];
var tabla2 = [];
var tablaAsist = [];
var count = 0;
class TablaClientes extends Component {


    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };

    constructor(props) {
        super(props);
        //this.SelectAlumno = this.SelectAlumno.bind(this);


        //this.show = this.show.bind(this);
        //this.close = this.close.bind(this);
        //this.Recargar = this.Recargar.bind(this);
        //this.eliminar = this.eliminar.bind(this);
        this.switchLocal = this.switchLocal.bind(this);


       // this.chageAsis = this.chageAsis.bind(this);

        this.state = {
            contenido: [],
            open: false,
            size: 'tiny',
            nameModal: "",
            idModal: "",
            indiceModal: "",
            startDate: new Date(),
            tipoB: 1,
            espacio: 0,
            paginas: 0,
            pagN: 0,
            tamFilas: 12,
            inicio: true,
            users:[],
            loading: true,
            paginas:0,
        }

        //console.log(this.props.clientes);
    }

    componentDidMount() {
        //this.setState({ loading: true });
        this.props.firebase.users().orderByChild("rol").equalTo("brand").on('value', snapshot => {
            const usersObject = snapshot.val();
            const expList = [];
            console.log(usersObject);
            if (usersObject) {
                const usersList = Object.keys(usersObject).map(key => ({
                    ...usersObject[key],
                    uidClient: key,

                }));
                console.log(usersList);

                for (var i = 0; i < usersList.length; i++) {
                    if (usersList[i].campains) {
                        const expL = Object.keys(usersList[i].campains).map(key => ({
                            ...usersList[i].campains[key],

                            nameUser: usersList[i].username,
                            uidClient: usersList[i].uidClient,
                            local: this.switchLocal(usersList[i].localization),


                            statusPay: Object.keys(usersList[i].campains[key].Payments).map(key2 => ({
                                s: usersList[i].campains[key].Payments[key2].status

                            })),


                            uid: key,
                        }))
                        for (var j = 0; j < expL.length; j++) {

                            expList.push(expL[j]);
                        }
                    }
                }

                console.log(expList);
                let np = Math.ceil(expList.length / this.state.tamFilas);


                let restan = (this.state.tamFilas - (expList.length - ((np - 1) * this.state.tamFilas)));
                console.log("vacios=  " + restan);
                if (expList.length == 0) restan = this.state.tamFilas;

                if (expList.length % this.state.tamFilas < this.state.tamFilas)
                    for (j = 0; j < restan; j++) {
                        const vacia2 = {
                            nameUser: "", status: "", expName: "", statusPay: [{s:""}],
                            cellExp: <Table.Cell singleLine>  </Table.Cell>,
                            cellClient: <Table.Cell singleLine>  </Table.Cell>,
                        }
                        expList.push(vacia2)

                    }
                console.log(expList);

                let filasTam = [5];
                if (expList.length > 10) filasTam = [5, 10];
                if (expList.length > 25) filasTam = [5, 10, 25];
                if (expList.length > 100) filasTam = [5, 10, 25, 100];

                this.setState({
                    users: expList,
                    loading: false,
                    paginas: np,
                    extraVoid: restan,
                    TamFilasArray: filasTam,
                }, () => this.setState({
                    open: false,
                    contenido: this.TablaInicial(0),
                    open: true,
                    inicio: false,

                }));

                console.log(this.state.users);
            }}
            );
    }

    componentWillUnmount() {
        this.props.firebase.users().off();
    }

    //componentWillMount() {
    //    const para = { tipo: 2, algo: "" }
    //    tabla = [];
    //    tabla2 = [];

    //}


    show(e, id) {
        this.setState({
            open: true,
            idModal: id,

        }, () => {
            this.setState({
                open: true,
                contenido: this.TablaInicial(this.state.pagN),

            })
        })
    }
    close() {
        this.setState({
            open: false,
        }, () => {
            this.setState({
                open: false,
                contenido: this.TablaInicial(this.state.pagN),
                open: true,
            })
        });
    }



    selectCampaing(e, id) {
        //console.debug("ID: " + id);


    }



    //Ordenar = (e, { value }) => {
    //    //console.log(tabla);
    //    var tabla3 = [];
    //    tabla3 = _.compact(this.state.users);

    //    if (this.state.extraVoid > 0) {
    //        tabla3 = _.dropRight(tabla3, this.state.extraVoid);
    //    }

    //    if (value == 4) {
    //        tabla3 = _.sortBy(tabla3, ['nameUser']);
    //    }
    //    if (value == 5) {
    //        tabla3 = _.sortBy(tabla3, ['status']);
    //    }
    //    if (value == 6) {
    //        tabla3 = _.sortBy(tabla3, ['expName']);
    //    }
    //    if (value == 7) {
    //        tabla3 = _.sortBy(tabla3, ['statusPay']);
    //    }
    //    if (value == 8) {
    //        tabla3 = _.sortBy(tabla3, ['localization']);

    //    }

    //    ///// refrescar la tabla
    //    tabla = tabla3;
    //    tabla2 = [];
    //    var auxTabla = [];




    //    let np = Math.ceil(tabla3.length / this.state.tamFilas);
    //    let restan = (this.state.tamFilas - (tabla3.length - ((np - 1) * this.state.tamFilas)));


    //    ///// LLenar los espacios de la tabla extra para que siempre sean 15 lineas
    //    if (tabla3.length % this.state.tamFilas < this.state.tamFilas)
    //        for (let j = 0; j < restan; j++) {
    //            const vacia2 = {
    //                nameUser: "", status: "", expName: "",
    //                cellExp: <Table.Cell singleLine>  </Table.Cell>,
    //                cellClient: <Table.Cell singleLine>  </Table.Cell>,}
    //            tabla3.push(vacia2)

    //        }

    //    this.setState({
    //        extraVoid: restan,
    //        //tipoB: value,
    //        users: tabla3,
    //        open: false,
    //    }, () => {
    //        this.setState({
    //            open: false,
    //            contenido: this.TablaInicial(0),
    //            open: true,
    //        })
    //    })


    //}




    //eliminar() {
    //    const data = { id: this.state.idModal, otro: "" };
    //    this.props.delAlumn(data).then(res => {
    //        if (res) {
    //            tabla.splice(this.state.indiceModal, 1);
    //            tabla2.splice(this.state.indiceModal, 1);
    //            const vacia = { alumno_nombre: "", alumno_apellidop: "", alumno_apellidom: "", id_Alumno: "" }
    //            tabla.push(vacia)

    //            const iconos = {
    //                status: "",
    //                editar: <i className="material-icons  white-text tiny valign-wrapper ">create</i>,
    //                eliminar: <Table.Cell textAlign="center"></Table.Cell>,
    //                asistencia: ""
    //            }
    //            tabla2.push(iconos);

    //            this.close();
    //        }
    //    })
    //}

    //Recargar() {
    //    console.log("SE SUPONE QUE DEBE REINICIAR");
    //    this.componentWillMount();
    //}


    handleChangePage = (event, newPage) => {
        this.Paginacion(newPage);
    };

    handleChangeRowsPerPage = (event) => {

        this.setState({ tamFilas: parseInt(event.target.value, 10) });
        // setRowsPerPage(parseInt(event.target.value, 10));
        this.Paginacion(0);
    };


    Paginacion = (num) => {
        let pag = num;

        this.setState({
            open: false,
            pagN: pag,
        }, () => {
            this.setState({
                open: false,
                contenido: this.TablaInicial(pag),
                open: true,
            })
        })
    }

    //SelectCliente(e, id) {


    //    this.setState({
    //        contenido: <PerfilClient id={id} />
    //    })

    //}

    //SelectExp(e, id, idCamp,name) {

    //    this.setState({
    //        contenido: <PerfilCamp id={id} idCamp={idCamp} nameClient={name}/>
    //    })

    //}


    TablaInicial = (pagina) => {
        let content = [];
        let styles = { fontSize: "12px" };
        const { classes } = this.props;
        const ordenOptions = [

            {
                key: 'Cliente',
                text: 'Ordenar por: Cliente',
                value: '4',
            },
            {
                key: 'Estatus',
                text: 'Ordenar por: Estatus',
                value: '5',
            },
            {
                key: 'Experiencia',
                text: 'Ordenar por: Experiencia',
                value: '6',
            },
            {
                key: 'Estatus Pago',
                text: 'Ordenar por: Estatus Pago',
                value: '7',
            },
            {
                key: 'Localizacion',
                text: 'Ordenar por: Localizacion',
                value: '8',
            },
        ]

        content.push(
            <div className={classes.tableResponsive}>
                <Paper elevation={2}>

                {/* <div className="row">
                    <div class="input-field col s3 offset-s1 " style={{ marginBottom: "0px", paddingBottom: "0px" }}>
                        <Dropdown
                            placeholder='Ordenar por:'
                            fluid
                            selection
                            id="tipoB"
                            onChange={this.Ordenar}
                            //value={this.state.tipoB}
                            options={ordenOptions}
                        />
                    </div>
                </div>*/}

                <Table size="small" className={classes.table} >
                    <TableHead className={classes["danger" + "TableHeader"]}>
                        <TableRow className={classes.tableHeadRow}>
                            <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>Cliente</TableCell>
                            <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>Estatus</TableCell>
                            <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>Experiencia</TableCell>
                            <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>Estatus pago</TableCell>
                            <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>Localizaci&oacute;n</TableCell>




                        </TableRow>
                    </TableHead>
                        {this.createTable(pagina)}

                </Table>
                <TablePagination
                    rowsPerPageOptions={this.state.TamFilasArray}
                    component="div"
                    count={this.state.users.length}
                    rowsPerPage={this.state.tamFilas}
                    page={this.state.pagN}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}

                />

                {/*<Modal
                    size={"tiny"}
                    open={this.state.open}
                    onClose={this.close}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}

                >
                    <Modal.Header>Realizar Baja</Modal.Header>
                    <Modal.Content>
                        <Container textAlign="right">Fecha <br /> {this.state.startDate.getDate()}/{this.state.startDate.getMonth()}/{this.state.startDate.getFullYear()}</Container>
                        <Container textAlign='justified'>
                            <br /> Esta seguro que desea dar de baja del sistema al
                             alumno "{this.state.nameModal}" con ID numero "{this.state.idModal}"

                         </Container >
                    </Modal.Content>
                    <Modal.Actions>

                        <Button negative onClick={this.close} >Cancelar</Button>
                        <Button onClick={this.eliminar} positive icon='checkmark' labelPosition='right' content='Eliminar' />
                    </Modal.Actions>
                </Modal>
                */}
                </Paper>
            </div>
        )
        return content;
    }
    createTable = (pagina) => {
        let styles = { fontSize: "10px", };
        var i = 0;
        let filas = [];
        var np = pagina * this.state.tamFilas
        //console.log(this.state.users);
        for (i = np; i < (np + this.state.tamFilas); i++) {
            // console.debug("indice: "+i);
            const id = this.state.users[i].uidClient;
            const idCamp = this.state.users[i].uid;
            const name = this.state.users[i].nameUser;
            //const nombre = tabla[i].alumno_nombre + " " + tabla[i].alumno_apellidop + " " + tabla[i].alumno_apellidom;
            var colorLuz = "white";
            var Hoverable = false;
            switch (this.state.users[i].status) {
                case "Waiting":
                    colorLuz = " yellow ";
                    Hoverable = true;

                    break;
                case "Active":
                    colorLuz = " green ";
                    Hoverable = true;
                    break;
                case "concluded":
                    colorLuz = " red ";
                    Hoverable = true;
                    break;
                case "":
                    colorLuz = "white";
                    break;
            }

            var colorLuz2 = "white-text";
            switch (this.state.users[i].statusPay[0].s) {
                case "Pending":
                    colorLuz2 = "yellow ";
                    break;
                case "Paid":
                    colorLuz2 = "green ";
                    break;
                case "Delayed":
                    colorLuz2 = " red  ";
                    break;
                case "":
                    colorLuz = "white";
                    break;
            }

            var cab = Hoverable ?

                <TableRow hover  >

                    <TableCell singleLine>{this.state.users[i].nameUser}    </TableCell>
                    <TableCell singleLine><Lens style={{ color: colorLuz }} /></TableCell>
                    <TableCell singleLine><Link to={{
                        pathname: ROUTES.PERFILCAMP,
                        state: {
                            id: id, idCamp: idCamp, nameClient: name
                        }

                    }}>{this.state.users[i].expName} </Link> </TableCell>
                    <TableCell singleLine><Lens style={{ color: colorLuz2 }} /></TableCell>
                    <TableCell singleLine>{this.switchLocal(this.state.users[i].localization)}</TableCell>

                    </TableRow >

                    :
                <TableRow  >
                    <TableCell singleLine>{""}</TableCell>
                    <TableCell singleLine><Lens style={{ color: colorLuz }} /></TableCell>
                    <TableCell singleLine>{""}</TableCell>
                    <TableCell singleLine>{""}</TableCell>
                    <TableCell singleLine>{""}</TableCell>

                </TableRow >;



            filas.push(
                <TableBody>
                    {cab}
                </TableBody>
            )

        }
        return filas;

    }

     switchLocal  (n)  {
        var local = "";
        //console.log(n);
        switch (n) {
            case 0: local = "Mexico"; break;
            case 1: local = "EE.UU."; break;
            case 2: local = "Canada"; break;
        }

        return local;
    }


    render() {

        return (
            <div>
                {this.state.contenido}
            </div>
        )
    }



}



const condition = authUser =>
    authUser && authUser.rol == ROLES.ADMIN;//[ROLES.ADMIN];
export default compose(
    withStyles(styles),
    withAuthorization(condition),
    withFirebase)
    (TablaClientes);
