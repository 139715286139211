

import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import Group from "@material-ui/icons/Group";

import Unarchive from "@material-ui/icons/Unarchive";
import EventNote from "@material-ui/icons/EventNote";
import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import Clients from "project/components/Clientes/Clientes";
import Campains from "project/components/Campains/Campains";
import HomePage from 'project/components/Home';
import sing from "project/components/SignIn";

import land from "project/components/Landing";


export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';

export const CLIENTES = '/Clientes/Clientes';
export const PAYSADMI = '/Clientes/Pays';
export const REGISTROCLIENTES = '/Clientes/RegistarClientes';
export const PERFILCAMP = '/Clientes/PerfilCamp';
export const CAMPAINS = '/Campains/Campains';
export const REGISTROCAMPAINS = '/Campains/RegistarCampain';
export const CLIENTEDASH = '/Clientes/ClientesDash';
export const ACTIVECAMPAIN = '/Clientes/ActiveCampain';
export const PROFILECLIENT = '/Clientes/ClientProfile';
export const REGISTROTARGET = '/Target/RegistarTarget';
export const SIGN_IN_T = '/Target/SignInTarget';
export const EXCHANGE_C = '/Target/ActiveCup';
export const LANDING_GAME = '/Target/LandingGame';

export const RoutesAdmin = [
    {
        path: HOME,
        name: "Home",
        rtlName: "ملف تعريفي للمستخدم",
        icon: Dashboard,
        component: HomePage,
        layout: ""
    },
    {
        path: CLIENTES,
        name: "Clientes",
        rtlName: "لوحة القيادة",
        icon: Group,
        component: Clients,
        layout: ""
    },
    {
        path: CAMPAINS,
        name: "Experiencias",
        rtlName: "ملف تعريفي للمستخدم",
        icon: Person,
        component: Campains,
        layout: ""
    },
    {
        path: PAYSADMI,
        name: "Pagos",
        rtlName: "قائمة الجدول",
        icon: "content_paste",
        component: sing,
        layout: ""
    },

];

export const RoutesBrand = [
    //{
    //    path: HOME,
    //    name: "Home",
    //    rtlName: "ملف تعريفي للمستخدم",
    //    icon: Dashboard,
    //    component: HomePage,
    //    layout: ""
    //},
    {
        path: CLIENTEDASH,
        name: "Campañas",
        rtlName: "ملف تعريفي للمستخدم",
        icon: EventNote,
        component: Campains,
        layout: ""
    },
    {
        path: PROFILECLIENT,
        name: "Perfil",
        rtlName: "التطور للاحترافية",
        icon: Person,
        component: land,
        layout: ""
    },

];

export const RoutesAdmin2 = [
    {
        path: HOME,
        name: "Home",
        rtlName: "ملف تعريفي للمستخدم",
        icon: Dashboard,
        component: HomePage,
        layout: ""
    },
    {
        path: CLIENTES,
        name: "Clientes",
        rtlName: "لوحة القيادة",
        icon: Group,
        component: Clients,
        layout: ""
    },
    {
        path: CAMPAINS,
        name: "Experiencias",
        rtlName: "ملف تعريفي للمستخدم",
        icon: Person,
        component: Campains,
        layout: ""
    },
    {
        path: SIGN_IN,
        name: "Pagos",
        rtlName: "قائمة الجدول",
        icon: "content_paste",
        component: sing,
        layout: ""
    },
    {
        path: LANDING,
        name: "Salir",
        rtlName: "قائمة الجدول",
        icon: "content_paste",
        component: sing,
        layout: ""
    },
];

export const RoutesBrand2 = [
    {
        path: HOME,
        name: "Home",
        rtlName: "ملف تعريفي للمستخدم",
        icon: Dashboard,
        component: HomePage,
        layout: ""
    },
    {
        path: CLIENTEDASH,
        name: "Campañas",
        rtlName: "ملف تعريفي للمستخدم",
        icon: EventNote,
        component: Campains,
        layout: ""
    },
    {
        path: PROFILECLIENT,
        name: "Perfil",
        rtlName: "التطور للاحترافية",
        icon: Person,
        component: land,
        layout: ""
    },
    {
        path: LANDING,
        name: "Salir",
        rtlName: "قائمة الجدول",
        icon: "content_paste",
        component: sing,
        layout: ""
    },
];
