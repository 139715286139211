// JavaScript source code
import React, { Component } from 'react';
import *as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import Tooltip from "@material-ui/core/Tooltip";
import { compose } from 'recompose';

import CardActionArea from '@material-ui/core/CardActionArea';


import { cardTitle } from "assets/jss/material-kit-react.js";

import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Face';
import TwitterIcon from '@material-ui/icons/Face';
import InstagramIcon from '@material-ui/icons/Face';
import WhatsAppIcon from '@material-ui/icons/Face';


import CircularProgress from '@material-ui/core/CircularProgress';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import title from './temp/title.png';

import { withStyles } from '@material-ui/core/styles';

import { withFirebase } from '../Firebase';
import './App.css';
const styles = {
    cardTitle,
    ...imagesStyles,
    textRight: {
        textAlign: "right"
    }
};

const useStyles = makeStyles(styles);

const grupoStyle = {
    width: 525, height: 312
}

const back = {
    background: "#F8D575",
    backgroundClip: "border-box",
}

class LandingGame extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            urlImg: "",
            loadGameImg: false,
            campData: {
                checkedFace: false,
                checkedTwitter: false,
                checkedInsta: false,
                checkedWhats: false,
                checkedDiscord: false,
            }
        };
    }


    componentDidMount() {
        this.setState({
            loading: true,
            existLand: false
        });

        let id = 0;
        let idCamp = 0;
        if (!this.props.idCliente) { id = this.props.location.state.idCliente; }
        else id = this.props.idCliente;
        if (!this.props.idCamp) idCamp = this.props.location.state.idCamp;
        else idCamp = this.props.idCamp;

        console.log("uid  " + id + " idCamp  " + idCamp );

        this.props.firebase.campain(id, idCamp).on('value', snapshot => {
            // this.props.firebase.users().on('value', snapshot => {
            const usersObject = snapshot.val();
            console.log(usersObject);


            this.props.firebase.getImagBrand0(id, idCamp, usersObject.branding.land0)
                .then((downloadURL) => {
                    console.log(downloadURL);
                    this.setState({ GameSRC: downloadURL, existLand:true});

                });
            this.props.firebase.getImagBrand0(id, idCamp, "legals")
                .then((downloadURL) => {
                    console.log(downloadURL);
                    if (downloadURL)
                        this.setState({legalsSRC: downloadURL, existLand: true });

                });
            this.props.firebase.experienceLink(usersObject.uidExp).on(
                'value', snapshot => {
                    // this.props.firebase.users().on('value', snapshot => {
                    const link = snapshot.val();
                    console.log(link);

                    this.setState({
                        statusGame: "Juega Ahora",
                        linkGame: link,
                    })
                })


            this.setState({
                campData: usersObject,
                texto: usersObject.textSocial,

                expName: usersObject.expName,
                link: usersObject.link,

                loading: false,
            });
        });
    }


    componentWillMount(){
        document.body.style.backgroundColor = "#80ced6";
    }

    componentWillUnmount() {
    this.props.firebase.experience().off();
    document.body.style.backgroundColor = null;
    }

    _onLoadGame = () => {
        this.setState(() => ({ loadGameImg: true }))
    }

    render() {
        const { classes } = this.props;
        return (


            <GridContainer
                direction="column"
                justify="space-between"
                alignItems="center" xs={12}

            >
                {this.state.existLand ?
                    <img className={classes.imgCardTop} src={this.state.GameSRC} style={grupoStyle} />
                    :
                    <img style={grupoStyle} src={title} />
                }



                <GridItem xs={10} sm={8} md={6} lg={4} xl={4}>
                    <p>

                        {!this.state.loading && this.state.texto}
                    </p>
                </GridItem>


                    <a id="linkJuego" href={this.state.linkGame} target="_blank">
                        <Button type="button" size="lg" color="danger"> Jugar</Button>

                    </a>


                <GridItem>
                    <GridContainer
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >

                        {this.state.campData.checkedFace &&

                            <GridItem xs={2} md={1}>
                                <Tooltip
                                    id="tooltip-left"
                                    title="Facebook"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                            >
                                <a id="linkJuego" href={this.state.campData.linkRS} target="_blank" >
                                    <IconButton aria-label="add to favorites">
                                        <i className={" fab fa-facebook"} />
                                    </IconButton>
                                </a>
                                </Tooltip>
                            </GridItem>}
                        {this.state.campData.checkedTwitter &&


                            <GridItem xs={2} md={1}>
                                <Tooltip
                                    id="tooltip-left"
                                    title="Twitter"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                            >
                                <a id="linkJuego" href={this.state.campData.linkRS2} target="_blank" >
                                    <IconButton aria-label="add to favorites">
                                        <i
                                            className={"fab fa-twitter"}
                                        />
                                    </IconButton>
                                </a>
                                </Tooltip>
                            </GridItem>
                        }
                        {this.state.campData.checkedInsta &&
                            <GridItem xs={2} md={1}>

                                <Tooltip
                                    id="tooltip-left"
                                    title="Instagram"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                            >
                                    <a id="linkJuego" href={this.state.campData.linkRS3} target="_blank" >
                                        <IconButton aria-label="add to favorites">
                                            <i
                                                className={"fab fa-instagram"}
                                            />
                                        </IconButton>
                                    </a>
                                </Tooltip>
                            </GridItem>
                        }
                        {this.state.campData.checkedWhats &&
                            <GridItem xs={2} md={1}>
                                <Tooltip
                                    id="tooltip-left"
                                    title="WhatsApp"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                            >
                                    <a id="linkJuego" href={this.state.campData.linkRS4} target="_blank" >
                                        <IconButton aria-label="add to favorites">
                                            <i
                                                className={"fab fa-whatsapp"}
                                            />
                                        </IconButton>
                                    </a>
                                </Tooltip>
                            </GridItem>
                        }
                        {this.state.campData.checkedDiscord &&
                            <GridItem xs={2} md={1}>
                                <Tooltip
                                    id="tooltip-left"
                                    title="Discord"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <a id="linkJuego" href={this.state.campData.linkRS5} target="_blank" >
                                        <IconButton aria-label="add to favorites">
                                            <i
                                                className={"fab fa-discord"}
                                            />
                                        </IconButton>
                                    </a>
                                </Tooltip>
                            </GridItem>
                        }
                    </GridContainer>
                </GridItem>
                <a id="linkJuego" href={this.state.legalsSRC} target="_blank" >
                <Button color="danger" simple>
                   Legales
                </Button>
               </a>

            </GridContainer>
          );

    }

}




export default compose(
   withStyles(styles),
    withFirebase)
    (LandingGame);

//export default withFirebase(CardCamp);
