import React from 'react';
import { Link } from 'react-router-dom';

import *as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';
import * as ROLES from '../../constants/roles';

import Header from "components/Header/Header.js";

const Head = () => (

    <AuthUserContext.Consumer>
        {authUser =>
            authUser ? (

                <Header
                    brand="Material Kit React"
                    rightLinks={<Link to={ROUTES.LANDING}>Sistema gamificado de campañas</Link>}
                    fixed
                    color="transparent"
                    changeColorOnScroll={{
                        height: 400,
                        color: "white"
                    }}

                />) : (
                    <div></div>
                    )
        }
    </AuthUserContext.Consumer>


);

export default Head;
