// JavaScript source code
import React, { Component } from 'react';
//import { Button, Card, Image } from 'semantic-ui-react'


// material-ui components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";


import CircularProgress from '@material-ui/core/CircularProgress';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { cardTitle } from "assets/jss/material-kit-react.js";

import * as ROLES from '../../constants/roles';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import { compose } from 'recompose';
import { cardStyles } from '../../react-class.js';
const styles = {
    cardTitle,
    ...cardStyles
};

const useStyles = makeStyles(styles);

function SampleFab (props) {
    const classes = useStyles();
    return (

    <div>
        { props.loading == true ?
                <Card >
                    <CardHeader color="danger">load</CardHeader>
                    <GridContainer direction="row"
                        justify="center"
                        alignItems="stretch" >
                        <GridItem xs={12}>
                            <CircularProgress color="primary" size={"100%"} />
                        </GridItem>
                    </GridContainer>
                 </Card >
                :

                <Card >
                  <CardHeader>
                    <CardIcon color="danger">
                      <p className={classes.cardTitleWhite}>{props.camp.name}</p>
                    </CardIcon>
                  </CardHeader>
                <CardBody>
                    <h4 className={classes.cardTitle}>{props.camp.link}</h4>
                    <p>
                        {props.camp.description}
                        </p>
                        {
                            props.camp.Status == "Active" ?
                                <Button color="danger" onClick={props.toogle}>Inabiltar</Button>
                                :
                                <Button color="danger" onClick={props.toogle}>Habiltar</Button>
                        }

                    </CardBody>
                </Card >
            }

       </div>

            );
}

class CardCamp extends Component {

    constructor(props) {
        super(props);
        this.toogleStatus = this.toogleStatus.bind(this);
        this.state = {
            loading: true,

        };
    }


    componentDidMount() {
        this.setState({
            loading: true,

            camp: {name:""},
        });
        console.log("uid  " + this.props.id );

        this.props.firebase.experience(this.props.id).on
            ('value',(camp) => {
                console.log(camp.val().name);
                this.setState({
                    camp: camp.val(),
                    loading: false,
                });

            });
    }

    componentWillUnmount() {
        this.props.firebase.experience().off();
    }

    toogleStatus() {
        if (this.state.camp.Status=="Active")
            this.props.firebase.experience(this.props.id).update({ Status: "disable" });
        else this.props.firebase.experience(this.props.id).update({ Status: "Active" });
    }
    render() {

        return (
            <div>

                <SampleFab camp={this.state.camp} loading={this.state.loading} toogle={this.toogleStatus} />
            </div>
                );


    }
 }
const condition = authUser =>
    authUser && authUser.rol == ROLES.ADMIN;//[ROLES.ADMIN];
export default compose(
    withAuthorization(condition),
    withFirebase)
    (CardCamp);
