import React from 'react';
import { Link } from 'react-router-dom';

import SignOutButton from '../SignOut';
import *as ROUTES from '../../constants/routes';
import { RoutesAdmin, RoutesBrand } from '../../constants/routes';

import { AuthUserContext } from '../Session';
import * as ROLES from '../../constants/roles';



// you would import our component like this
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown';
import Badge from 'components/Badge/Badge.js';
import Navbar from "components/Navbars/Navbar.js";
const trigger = (
    <span>
        {/* <Icon name='bars' size='small' />*/}
    </span>
)

//const options = [
//    {
//        key: 'user',
//        text: (
//            <span>
//                Signed in as <strong>{authUser.email}</strong>
//            </span>
//        ),
//        disabled: true,
//    },
//    { key: 'profile', text: ( <Link to={ROUTES.ACCOUNT}>Account</Link>) },
//    { key: 'settings', text: 'Settings' },
//    { key: 'sign-out', text: (<SignOutButton />) },
//]



function Navbar2({ ...rest }) {

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    return (
        <div className="grey">
            <AuthUserContext.Consumer>
                {authUser =>
                    authUser ? <Navbar
                        routes={authUser.rol == ROLES.ADMIN ? RoutesAdmin : RoutesBrand}
                        handleDrawerToggle={handleDrawerToggle}
                        {...rest}
                    /> : <NavNonAuth authUser={authUser} />
                }
            </AuthUserContext.Consumer>
        </div>
    )
};
const NavAuth = ({ authUser }) => (

    <GridContainer>
        <GridItem xs={3}>
            {/*
            <Dropdown trigger={trigger} floating button >
            <Dropdown.Menu >
                    <Dropdown.Item icon='attention' text={
                        <span>
                            Signed in as <strong>{authUser.username}</strong>
                        </span>
                    } />
                    <Dropdown.Item key='profile' text={<Link to={ROUTES.ACCOUNT}>Account</Link>} />

                    {authUser.rol == ROLES.ADMIN && (
                        <Dropdown.Item key='profile' text={<Link to={ROUTES.ADMIN}>Admin</Link>} />
                    )}
                    <Dropdown.Item key='sing-out' text={<SignOutButton />} />
                </Dropdown.Menu>
            </Dropdown>
            */}

            <CustomDropdown

                buttonIcon="view_list"
                buttonProps={{
                    round: true,
                    color: "info"
                }}
                dropdownList={
                    authUser.rol != ROLES.ADMIN ?
                        ([
                            <span>
                                Signed in as <strong>{authUser.username}</strong>
                            </span>,
                            <Link to={ROUTES.ACCOUNT}>Account</Link>,
                            { divider: true },
                            <SignOutButton />,

                        ]) :
                        ([
                            <span>
                                Signed in as <strong>{authUser.username}</strong>
                            </span>,
                            <Link to={ROUTES.ACCOUNT}>Account</Link>,
                            <Link to={ROUTES.ADMIN}>Admin</Link>,
                            { divider: true },
                            <SignOutButton />,

                        ])

                    }
            />

        </GridItem>
        <GridItem  xs={3}>
            <div className="header">{authUser.rol} </div>
        </GridItem>
    </GridContainer>
);

const NavNonAuth = ({ authUser }) => (

    <div className="grey">

    </div>
);


export default Navbar2;
