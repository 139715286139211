// JavaScript source code
import React, { Component } from 'react';
import *as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import CardIcon from "components/Card/CardIcon";
import Tooltip from "@material-ui/core/Tooltip";
import { compose } from 'recompose';

import CardActionArea from '@material-ui/core/CardActionArea';


import { cardTitle } from "assets/jss/material-kit-react.js";
import poster from "../Clientes/img/meca.png";
import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { cardStyles } from '../../react-class.js';
import { withFirebase } from '../Firebase';
const styles = {
    cardTitle,
    ...imagesStyles,
    textRight: {
        textAlign: "right"
    },
    ...cardStyles
};

const useStyles = makeStyles(styles);

function SelectExp(e, id, idCamp, name) {

    {/*<PerfilCamp id={id} idCamp={idCamp} nameClient={name} />
     */}



}



function SampleFab(props) {
    const classes = useStyles();

    return (
        <Card >
            <CardHeader>
              <CardIcon color="danger">
                <p className={classes.cardTitleWhite}>{props.name}</p>
              </CardIcon>
            </CardHeader>
            <CardBody>
                <CardActionArea onClick={(e) => { SelectExp(e, props.idCliente, props.idCamp, props.name) }} style={{ padding: "20px"  }}>
                    <Link to={{
                        pathname: ROUTES.PERFILCAMP,
                        state: {
                            id: props.idCliente, idCamp: props.idCamp, nameClient: props.nameCliente
                        }

                    }}>
                        <img className={classes.imgCardTop} src={"/mcLogo.png" /*props.img*/} alt="Card-img-cap" onLoad={props._onLoadGame} style={{ display: props.loadImg }} />

                        {!props.loadImg &&
                            <GridContainer direction="row"
                                justify="center"
                                alignItems="stretch">
                                <GridItem xs={12}>
                                    <CircularProgress color="danger" size={"100%"} />
                                </GridItem>
                            </GridContainer>
                        }
                    </Link>
                </CardActionArea>

                <h4 className={classes.cardTitle}>{props.link}</h4>
                <p>
                    {props.description}
                    Estado: Activa
                </p>

            </CardBody>

                <div className={classes.textRight}>
                        <Tooltip
                            id="tooltip-left"
                            title="Editar"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                        >
                            <IconButton aria-label="add to favorites">
                                <Edit />
                            </IconButton>
                        </Tooltip>

            </div>




        </Card >);
}

class CardCamp2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            urlImg: "",
            loadGameImg: false,
        };
    }


    componentDidMount() {
        this.setState({ loading: true });
        console.log("uid:  " + this.props.idCliente + " idCamp : " + this.props.idCamp + "  img: " + this.props.img + " exp: " + this.props.experience);

        //this.props.firebase.getImagBrand0(this.props.idCliente, this.props.idCamp, this.props.img)
        //.then((downloadURL)=> {
        //    console.log("url : " + downloadURL);
        //    this.setState({ urlImg: downloadURL });
        //}).catch( e =>{
        //  this.props.firebase.experience(this.props.experience).on('value', snapshot => {
        //    const gameExp = snapshot.val();
        //    this.props.firebase.getGameImg(gameExp.image).then((downloadURL)=> {
        //        console.log("url : " + downloadURL);
        //        this.setState({ urlImg: downloadURL });
        //    });
        //  })
        //});
    }

    componentWillUnmount() {
        this.props.firebase.experience().off();
    }

    _onLoadGame = () => {
        this.setState(() => ({ loadGameImg: true }))
    }

    render() {

        return (
            <div>
                <SampleFab name={this.props.name} nameCliente={this.props.nameCliente} idCliente={this.props.idCliente} idCamp={this.props.idCamp} link={this.props.link} description={this.props.description} status={this.props.status} img={this.state.urlImg} _onLoadGame={this._onLoadGame} loadImg={this.state.loadGameImg} />
            </div>);

    }

}
export default compose(

    withFirebase)
    (CardCamp2);

//export default withFirebase(CardCamp);
