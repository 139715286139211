import React from 'react';
import { Link } from 'react-router-dom';

import SignOutButton from '../SignOut';
import *as ROUTES from '../../constants/routes';
import { RoutesAdmin, RoutesBrand, RoutesAdmin2, RoutesBrand2 } from '../../constants/routes';
import { AuthUserContext } from '../Session';
import * as ROLES from '../../constants/roles';
import Sidebar from "components/Sidebar/Sidebar.js";


import bgImage from "assets/img/fondo.png";
import logo from "../Landing/img/logoWhite.png";
import Navbar from "components/Navbars/Navbar.js";

// core components/views for RTL layout


function Navigation({ ...rest }) {

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    return (
        <div>
            <AuthUserContext.Consumer>

             {authUser =>
                    authUser ?
                        <div>
                            {(authUser.rol == ROLES.ADMIN || authUser.rol == ROLES.BRAND) ?
                                <div>
                            <Sidebar
                                routes={authUser.rol == ROLES.ADMIN ? RoutesAdmin : RoutesBrand}
                                logoText={"Game Campaigns"}
                                logo={logo}
                                image={bgImage}
                                handleDrawerToggle={handleDrawerToggle}
                                open={ mobileOpen }
                                color="red"
                                {...rest}
                            />
                            <Navbar
                                handleDrawerToggle={handleDrawerToggle}
                                dropdownList={
                                    authUser.rol != ROLES.ADMIN ?
                                        ([
                                            <span>
                                                Signed in as <strong>{authUser.username}</strong>
                                            </span>,
                                            <SignOutButton />,

                                        ]) :
                                        ([
                                            <span>
                                                Signed in as <strong>{authUser.username}</strong>
                                            </span>,
                                            <Link to={ROUTES.ACCOUNT}>Account</Link>,
                                            <Link to={ROUTES.ADMIN}>Admin</Link>,
                                            <SignOutButton />,
                                        ])

                                }
                                {...rest}
                                />
                                </div>
                                : <NavigationNonAuth />
                                }
                        </div>
                    : <NavigationNonAuth />
                }
            </AuthUserContext.Consumer>
        </div>
    );
}

const NavigationNonAuth = () => (
    <ul>

    </ul>
);



export default Navigation;
