import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.8.0";

import * as serviceWorker from './serviceWorker';

import App from 'project/components/App';
import Firebase, { FirebaseContext } from 'project/components/Firebase';



var hist = createBrowserHistory();

ReactDOM.render(
    <FirebaseContext.Provider value={new Firebase()}>
        <App />
    </FirebaseContext.Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();
