import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
//import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";
//import { Dropdown } from 'semantic-ui-react';

// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import InputLabel from "@material-ui/core/InputLabel";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from '@material-ui/core/IconButton';
import Switch from "@material-ui/core/Switch";
import CardIcon from "components/Card/CardIcon";

import Archive from "@material-ui/icons/Archive";
import Publish from "@material-ui/icons/Publish";
import Support from '@material-ui/icons/ContactSupport';

import { makeStyles } from "@material-ui/core/styles";

import { withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    input: {
        display: 'none',
    },
};

const ActiveCampain = () => (
  <div>
        <ActiveCampainsForm/>
  </div>
);

const INITIAL_STATE = {
    startDate: Datetime.moment().add(3, 'days'),
    endDate: "",
    startDateY: new Date().getFullYear(),
    startDateM: new Date().getMonth(),
    startDateD: new Date().getDay(),
    endDateY: '',
    endDateM: '',
    endDateD: '',
    experiences: [],
    localization: '',
    exp: "",
    expName: "",

    linkRS: "",
    linkRS2:"",
    linkRS3: "",
    linkRS4: "",
    linkRS5: "",
    TokensType: "",

    rangeAgeFloor: "",
    rangeAgeCeil: "",
    gender:"",
    TokensMount: "",
    legals: "",
    textSocial: "",

    branding:"",
    landing:"",
    locals: [{ key: 0, text: "Mexico", value: "0" }, { key: 1, text: "EE.UU.", value: "1" }, { key: 2, text: "Canada", value: "2" } ],
    link:'',
    error: null,
    errorBrand: null,
    errorLand: null,
    estatus: 'Waiting',
   
    selectBrand: false,
    selectLand: false,
    selectLegals: false,
    selectEnd: false,
    selectExp: false,

    checkedFace: true,
    checkedTwitter: false,
    checkedInsta: false,
    checkedWhats: false,
    checkedDiscord: false,

};






class ActiveCampainsFormBase extends Component {


  constructor(props) {
      super(props);

      this.handleChange = this.handleChange.bind(this);
      this.handleChange2 = this.handleChange2.bind(this);
      this.expChange = this.expChange.bind(this);
      this.GeneratePays = this.GeneratePays.bind(this);
      this.state = { ...INITIAL_STATE }

  }


    componentDidMount() {
        this.setState({ loading: true });
        this.props.firebase.experiences().on('value', snapshot => {
            const campainsObject = snapshot.val();
            const campainsList = Object.keys(campainsObject).map(key => ({
                text: campainsObject[key].name,
                value: key,
                Status: campainsObject[key].Status,
                uid: key,
                branding: campainsObject[key].branding,
                brandingLand: campainsObject[key].brandingLand,
            }));
            console.log(campainsList);

            const expList = [];
            let i = 0;
            for (i = 0; i < campainsList.length; i++) {
                if (campainsList[i].Status=="Active")
                    expList.push(<MenuItem value={campainsList[i].value}>{campainsList[i].text}</MenuItem>);
            }

            this.setState({
                experiences: expList,
                campL: campainsList,
                loading: false,
            });
        });


    }

    componentWillUnmount() {
        this.props.firebase.experience().off();
    }



    onCreateCampain = (event, authUser) => {
        //var profile = { this.state.rangeAgeFloor, this.state.rangeAgeCeil, this.state.gender };

        var pays = this.GeneratePays();


        var claveCamp= this.props.firebase.campains(authUser.uid).push({

            startDate: { Year: this.state.startDateY, Month: this.state.startDateM, Day: this.state.startDateD, },
            endDate: { Year: this.state.endDateY, Month: this.state.endDateM, Day: this.state.endDateD, },
            editDate: { Year: this.state.startDateY, Month: this.state.startDateM, Day: this.state.startDateD, },
            targetProfile: { AgeFloor: this.state.rangeAgeFloor, AgeCiel: this.state.rangeAgeCeil, Gender: this.state.gender },
            uidExp: this.state.exp,
            expName: this.state.expName,
            link: this.state.link,
            localization: this.state.localization,
            status: this.state.estatus,
            linkRS: this.state.linkRS,
            linkRS2: this.state.linkRS2,
            linkRS3: this.state.linkRS3,
            linkRS4: this.state.linkRS4,
            linkRS5: this.state.linkRS5,
            checkedFace: this.state.checkedFace,
            checkedTwitter: this.state.checkedTwitter,
            checkedInsta: this.state.checkedInsta,
            checkedWhats: this.state.checkedWhats,
            checkedDiscord: this.state.checkedDiscord,

            TokensType: this.state.TokensType,
            TokensMount: this.state.TokensMount,
            textSocial: this.state.textSocial,
            branding: this.state.branding,
            Payments:  this.GeneratePays() ,

        }).then((snap) => {
            const files = [];
            for (var clave in this.state.brandingFile) {
                // Controlando que json realmente tenga esa propiedad
                if (this.state.brandingFile.hasOwnProperty(clave)) {
                    // Mostrando en pantalla la clave junto a su valor
                    //md  console.log("La clave es " + clave + " y el valor es " + this.state.targetProfile[clave]);
                    files.push(

                        this.state.brandingFile[clave]

                    );
                }
            }
            console.log(this.state.brandingFile);

            for (let i = 0; i < files.length; i++) {
                //console.log(this.state.branding["file" + i]);
                 this.props.firebase.campainBranding(authUser.uid, snap.key, this.state.branding["file"+i]).put(files[i]);

            }


            const files2 = [];
            for (var clave in this.state.landingFile) {
                // Controlando que json realmente tenga esa propiedad
                if (this.state.landingFile.hasOwnProperty(clave)) {
                    // Mostrando en pantalla la clave junto a su valor
                    //md  console.log("La clave es " + clave + " y el valor es " + this.state.targetProfile[clave]);
                    files2.push(

                        this.state.landingFile[clave]

                    );
                }
            }
            console.log(files2);

            for (let i = 0; i < files2.length; i++) {
                //console.log(this.state.branding["file" + i]);
                this.props.firebase.campainBranding(authUser.uid, snap.key, this.state.branding["land" + i]).put(files2[i]);

            }
            //var uploadTask2 = this.props.firebase.campainBranding(authUser.uid, snap.key, this.state.branding.land0).put(files2[0]);

            var uploadTask3 = this.props.firebase.campainBranding(authUser.uid, snap.key, "legals").put(this.state.LegalsFile);
            // Listen for state changes, errors, and completion of the upload.
            //uploadTask.on(this.props.firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            //     (snapshot) =>{
            //        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            //        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            //        console.log('Upload is ' + progress + '% done');
            //        switch (snapshot.state) {
            //            case this.props.firebase.storage.TaskState.PAUSED: // or 'paused'
            //                console.log('Upload is paused');
            //                break;
            //            case this.props.firebase.storage.TaskState.RUNNING: // or 'running'
            //                console.log('Upload is running');
            //                break;
            //        }
            //    }, function (error) {

            //        // A full list of error codes is available at
            //        // https://firebase.google.com/docs/storage/web/handle-errors
            //        switch (error.code) {
            //            case 'storage/unauthorized':
            //                // User doesn't have permission to access the object
            //                break;

            //            case 'storage/canceled':
            //                // User canceled the upload
            //                break;


            //            case 'storage/unknown':
            //                // Unknown error occurred, inspect error.serverResponse
            //                break;
            //            }
            //        }, function() {
            //            // Upload completed successfully, now we can get the download URL
            //            uploadTask.snapshot.ref.getDownloadURL().then( (downloadURL) =>{
            //                console.log('File available at', downloadURL);
            //                this.props.firebase.campain(authUser.uid, snap.key).update({
            //                    "branding/file0": downloadURL,
            //                })
            //            });
            //        });
            ////    .then(() => {
            ////    this.props.firebase.getImagBrand0((authUser.uid, snap.key, this.state.branding.file0).then( (url)=> {
            ////        console.log(url);
            ////        this.props.firebase.campain(authUser.uid,snap.key).update({
            ////            "branding/file0":url,
            ////        })

            ////    }))


            ////});

        }).then(() => {
            this.setState({ ...INITIAL_STATE });

            this.props.history.push(ROUTES.CLIENTEDASH);

            })
            .catch(error => {
                this.setState({ error });
            });;

        event.preventDefault();
    };

    onChange = (e) => {


        this.setState({ [e.target.id]: e.target.value });
    }

    handleFileUpload = e => {
        console.log(e.target.files);
        let file = {};
        let campN = {};
        let brand = [];
        let auxFile=[]
        let n = 0,l=0;


        for (let i = 0; i < this.state.campL.length; i++) {
            if (this.state.campL[i].value == this.state.exp) {

                brand = this.state.campL[i].branding;
                break;
            }
        }
        if (brand.length != e.target.files.length) {
            this.setState({
                errorBrand: {message: "Los archivos deben ser la misma cantidad"},
            });
        } else {
            //console.log(brand.length + "  " + e.target.length);
            for (let j = 0; j < brand.length; j++) {
                for (let k = 0; k < e.target.files.length; k++) {
                    //console.log("B:  " + brand[j] + " T:  " + e.target[k].name);
                    if (brand[j] === e.target.files[k].name) {
                        n++;
                        break;
                    }
                }
            }
            console.log("N:" + n);
            if (n == brand.length) { // se encuentran todos los archivos nombrados correctamente

                // ordenamiendo para que MainIcon sea el file0
                for (let i=0; i < e.target.files.length; i++) {
                    if (e.target.files[i].name == "MainIcon.png") {
                        auxFile.push(e.target.files[i]);
                        break;
                    }
                }
                for (let i=0; i < e.target.files.length; i++) {
                    if (e.target.files[i].name != "MainIcon.png") {
                        auxFile.push(e.target.files[i]);

                    }
                }

                for (l = 0; l < e.target.files.length; l++) {
                    //file[l] = e.target.files[l];
                    file= {...file, [auxFile[l].name]: auxFile[l] };
                    campN={...campN, ["file" + l]: auxFile[l].name };
                }
                console.log(file);
                console.log(campN);
                this.setState({
                    brandingFile: { ...file },
                    branding: { ...this.state.branding, ...campN },
                    errorBrand:"",
                    selectBrand: true,
                });
            }
            else {
                this.setState({
                    errorBrand: { message: "Todos los archivos deben tener los mismos nombre que los originales" },
                });

            }
        }

    }

    handleFileUpload2 = e => {



        console.log(e.target.files);
        let file = {};
        let campN = {};
        let brand = [];
        let auxFile = []
        let n = 0, l = 0;

        for (let i = 0; i < this.state.campL.length; i++) {
            if (this.state.campL[i].value == this.state.exp) {

                brand = this.state.campL[i].brandingLand;
                break;
            }
        }
        console.log(brand);
        if (brand.length != e.target.files.length) {
            this.setState({
                errorLand: { message: "Los archivos deben ser la misma cantidad" },
            });
        } else {
            //console.log(brand.length + "  " + e.target.length);
            for (let j = 0; j < brand.length; j++) {
                for (let k = 0; k < e.target.files.length; k++) {
                    //console.log("B:  " + brand[j] + " T:  " + e.target[k].name);
                    if (brand[j] === e.target.files[k].name) {
                        n++;
                        break;
                    }
                }
            }
            console.log("N:" + n);
            if (n == brand.length) { // se encuentran todos los archivos nombrados correctamente

                // ordenamiendo para que ________ sea el file0
                for (let i = 0; i < e.target.files.length; i++) {
                    if (e.target.files[i].name == "land1.png") {
                        auxFile.push(e.target.files[i]);
                        break;
                    }
                }
                for (let i = 0; i < e.target.files.length; i++) {
                    if (e.target.files[i].name != "land1.png") {
                        auxFile.push(e.target.files[i]);

                    }
                }

                for (l = 0; l < e.target.files.length; l++) {
                    //file[l] = e.target.files[l];
                    file = { ...file, [auxFile[l].name]: auxFile[l] };
                    campN = { ...campN, ["land" + l]: auxFile[l].name };
                }
                console.log(file);
                console.log(campN);
                this.setState({
                    landingFile: { ...file },
                    branding: { ...this.state.branding, ...campN },
                    errorLand: "",
                    selectLand: true,
                });
            }
            else {
                this.setState({
                    errorLand: { message: "Todos los archivos deben tener los mismos nombres que los originales" },
                });

            }
        }


    }

    handleFileUpload3 = e => {

        const file = e.target.files[0];


        this.setState({
            LegalsFile: file ,
            selectLegals: true,
        });
    }

    GeneratePays() {
        var nP = 1,i=0;
        const pays = [];


        for (i = 0; i < nP; i++) {
            var mili = this.state.startDate.getTime() ;
            var date = new Date(mili + 2592000000 );

            pays.push({
                date: {
                    Year: date.getFullYear(),
                    Month: date.getMonth(),
                    Day: date.getDate(),
                },
                Mount: 1000,
                status: "Pending",
            });
        }
        console.log(pays);
        return pays;

    }

    expChange = (e, { value }) => {
        var name = "";
        var i = 0;

        console.log("valor  " + e.target.value);

        for (i = 0; i < this.state.campL.length; i++) {
            if (this.state.campL[i].value == e.target.value)
                name = this.state.campL[i].text;
        }
        this.props.firebase.downloadBrand(e.target.value).then((downloadURL) => {
            console.log(downloadURL);
            this.setState({ urlDownload: downloadURL });

        });
        this.props.firebase.downloadBrandLand(e.target.value).then((downloadURL) => {
            console.log(downloadURL);
            this.setState({ urlDownloadLand: downloadURL });

        });

        this.setState({
            exp: e.target.value,
            expName: name,
            selectExp: true,
            selectBrand: false,
            selectLand:false,
        }

        );
        //console.log("v: "+value+"  name: "+name);
    }

    localChange = (e, { value }) => {
        this.setState({ localization: e.target.value }

        );
    }

    GenderChange = (e, { value }) => {
        this.setState({ gender: e.target.value });
    }
    AgeCeilChange = (e, { value }) => {
        this.setState({ rangeAgeCeil: e.target.value });
    }
    AgeFloorChange = (e, { value }) => {
        this.setState({ rangeAgeFloor: e.target.value });
    }
    TokensTypeChange = (e, { value }) => {
        this.setState({ TokensType: e.target.value });
    }
    TokensMountChange = (e, { value }) => {
        this.setState({ TokensMount: e.target.value });
    }


    handleChange(date) {
        var fechaN = 0;
        var fechaR = 0;
        var fecha = new Date();
        var date2 = new Date(date);
        var estatus = "Waiting"
        if (date < fecha) estatus = "Active";
        console.log("fecha:  " +date2);

        this.setState({
            startDate: date2,
            startDateY: date2.getFullYear(),
            startDateM: date2.getMonth(),
            startDateD: date2.getDate(),
            estatus: estatus,
            endDate: "",
            endDateY: "",
            endDateM: "",
            endDateD: "",
            selectEnd: false,
        })
    }
    handleChange2(date) {
        var date2 = new Date(date);
        this.setState({
            endDate: date2,
            endDateY: date2.getFullYear(),
            endDateM: date2.getMonth(),
            endDateD: date2.getDate(),
            selectEnd: true,
        })

    }

    render() {
        const {
            name,
            link,
            description,
            image,
            errorBrand,
            errorLand,
            error,
        } = this.state;

        const isInvalid =
            this.state.selectEnd === false ||
            this.state.selectExp === false ||
            this.state.selectBrand === false ||
            this.state.selectLand === false ||
            this.state.selectLegals === false;

        var day2 = Datetime.moment().add (2, 'day');
        var valid = function (current) {
            return current.isAfter(day2);
        };
        var afterStart = this.state.startDate;
        var valid2 = function (current) {
            return current.isAfter(afterStart);
        };
        const { classes } = this.props;
        const opcloc = [<MenuItem value={0}>Mexico</MenuItem>, <MenuItem value={1}>EE.UU.</MenuItem>, <MenuItem value={2}>Canada</MenuItem>];
        const opcsexo = [<MenuItem value={0}>Masculino</MenuItem>, <MenuItem value={1}>Femenino</MenuItem>, <MenuItem value={2}>Mixto</MenuItem> ];
        const opcTokens = [<MenuItem value={0}>Tipo 1</MenuItem>, <MenuItem value={1}>Tipo 2</MenuItem>, <MenuItem value={2}>Tipo 3</MenuItem>];
        const opcTokensN = [<MenuItem value={0}>10</MenuItem>, <MenuItem value={10}>100</MenuItem>, <MenuItem value={500}>500</MenuItem>, <MenuItem value={1000}>1000</MenuItem>, <MenuItem value={5000}>5000</MenuItem>,];
        const rangoEdad =[];// [<MenuItem value={5}>5</MenuItem>, <MenuItem value={6}>6</MenuItem>, <MenuItem value={7}>7</MenuItem>, <MenuItem value={8}>8</MenuItem>, <MenuItem value={9}>9</MenuItem>, <MenuItem value={10}>10</MenuItem>, <MenuItem value={11}>11</MenuItem>, <MenuItem value={12}>12</MenuItem>, <MenuItem value={13}>13</MenuItem>, <MenuItem value={14}>14</MenuItem>, <MenuItem value={15}>15</MenuItem>];
        for (let i = 5; i < 100; i++) {
            rangoEdad.push(
                <MenuItem value={i}>{i}</MenuItem>
            );
        }

        return (



            <AuthUserContext.Consumer>
                {authUser => (

                    <Card >
                        <CardHeader>
                              <CardIcon color="danger">
                                <h4 className={classes.cardTitleWhite}>Activar campa&ntilde;a</h4>
                                <p className={classes.cardCategoryWhite}>Registra los datos</p>
                              </CardIcon>
                        </CardHeader>
                        <form onSubmit={event => this.onCreateCampain(event, authUser)}>
                            <CardBody>
                                <GridContainer container
                                    direction="row"
                                    justify="space-around"
                                    alignItems="center">
                                    <GridContainer direction="column"
                                        justify="space-evenly"
                                        alignItems="center" xs={12} sm={8} md={6} lg={4}>
                                        <GridItem>

                                            <GridContainer
                                                direction="row"
                                                justify="flex-start"
                                                alignItems="center"
                                            >
                                                <GridItem xs={10}>
                                                    <InputLabel className={classes.label}>
                                                        Fecha de inicio
                                                    </InputLabel>
                                                    <br />
                                                    <FormControl fullWidth>
                                                        <Datetime
                                                            value={this.state.startDate}
                                                            inputProps={{ placeholder: "Datetime Picker Here" }}
                                                            onChange={this.handleChange}
                                                            isValidDate={valid}
                                                        />
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem xs={1}>
                                                    <Tooltip
                                                        id="tooltip-left"
                                                        title="Fecha en la que se activara la campa&ntilde;a y se podra acceder a la experiencia"
                                                        placement="top"
                                                        classes={{ tooltip: classes.tooltip }}
                                                    >
                                                        <IconButton >
                                                            <Support />
                                                        </IconButton>


                                                    </Tooltip>
                                                </GridItem>
                                            </GridContainer>



                                            <br/>
                                        </GridItem>
                                        <GridItem >


                                            <GridContainer
                                                direction="row"
                                                justify="flex-start"
                                                alignItems="center"
                                            >
                                                <GridItem xs={10}>
                                                    <InputLabel className={classes.label}>
                                                        Fecha de termino
                                                    </InputLabel>
                                                    <br />
                                                    <FormControl fullWidth>
                                                        <Datetime
                                                            value={this.state.endDate}
                                                            onChange={this.handleChange2}
                                                            inputProps={{ placeholder: "Datetime Picker Here" }}
                                                            isValidDate={valid2}
                                                        />
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem xs={1}>
                                                    <Tooltip
                                                        id="tooltip-left"
                                                        title="Fecha en la cual se cerrara la campa&ntilde;a"
                                                        placement="top"
                                                        classes={{ tooltip: classes.tooltip }}
                                                    >
                                                        <IconButton >
                                                            <Support />
                                                        </IconButton>


                                                    </Tooltip>
                                                </GridItem>
                                            </GridContainer>



                                        </GridItem>

                                        <GridItem >

                                            <GridContainer
                                                direction="row"
                                                justify="flex-start"
                                                alignItems="center"
                                            >
                                                <GridItem xs={10}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="expL">Selecciona Experiencia</InputLabel>
                                                        <Select
                                                            labelId="expL"
                                                            id="exp"
                                                            value={this.state.exp}
                                                            onChange={this.expChange}
                                                        >
                                                            {this.state.experiences}
                                                        </Select>
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem xs={1}>
                                                    <Tooltip
                                                        id="tooltip-left"
                                                        title="Juego a utilizar como base"
                                                        placement="top"
                                                        classes={{ tooltip: classes.tooltip }}
                                                    >
                                                        <IconButton >
                                                            <Support />
                                                        </IconButton>


                                                    </Tooltip>
                                                </GridItem>
                                            </GridContainer>


                                        </GridItem>
                                        <GridItem >
                                            <GridContainer
                                                direction="row"
                                                justify="flex-start"
                                                alignItems="center"
                                            >
                                                <GridItem xs={10}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="localization">Localizacion</InputLabel>
                                                        <Select
                                                            labelId="localization"
                                                            id="localization"
                                                            value={this.state.localization}
                                                            onChange={this.localChange}
                                                        >
                                                            {opcloc}
                                                        </Select>
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem xs={1}>
                                                    <Tooltip
                                                        id="tooltip-left"
                                                        title="Pais objetivo de la campa&ntilde;a"
                                                        placement="top"
                                                        classes={{ tooltip: classes.tooltip }}
                                                    >
                                                        <IconButton >
                                                            <Support />
                                                        </IconButton>


                                                    </Tooltip>
                                                </GridItem>
                                            </GridContainer>


                                        </GridItem>
                                        <GridItem >

                                            <GridContainer
                                                direction="row"
                                                justify="flex-start"
                                                alignItems="center"
                                            >
                                                <GridItem xs={10}>
                                                    <CustomInput
                                                        labelText="Link deseado de juego"
                                                        id="link"

                                                        value={this.state.link}
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            onChange: this.onChange
                                                        }}
                                                    />

                                                </GridItem>
                                                <GridItem xs={1}>
                                                    <Tooltip
                                                        id="tooltip-left"
                                                        title="Link al que tendran que acceder los usuarios para jugar la experiencia. El dominio puede tardar un par de dias en habilitarse"
                                                        placement="top"
                                                        classes={{ tooltip: classes.tooltip }}
                                                    >
                                                        <IconButton >
                                                            <Support />
                                                        </IconButton>


                                                    </Tooltip>
                                                </GridItem>
                                            </GridContainer>



                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer direction="column"
                                        justify="space-evenly"
                                        alignItems="center" xs={12} sm={8} md={6} lg={4}>
                                        <GridItem>

                                            <GridContainer
                                                direction="row"
                                                justify="flex-start"
                                                alignItems="center"
                                            >
                                                <GridItem xs={10}>

                                                    <h5>Perfil del jugador</h5>
                                                </GridItem>
                                                <GridItem xs={1}>
                                                    <Tooltip
                                                        id="tooltip-left"
                                                        title="Datos demograficos que se usaran para determinar que tanto exito tiene la campa&ntilde;a con respecto al tipo de jugador real"
                                                        placement="top"
                                                        classes={{ tooltip: classes.tooltip }}
                                                    >
                                                        <IconButton >
                                                            <Support />
                                                        </IconButton>


                                                    </Tooltip>
                                                </GridItem>
                                            </GridContainer>



                                        </GridItem >


                                        <GridItem  >
                                            <span style={{ margin: "0px", padding: "0px", fontSize: "12px" }} >Rango de edad</span>
                                        </GridItem>

                                            <GridItem  >

                                                <FormControl fullWidth>
                                                    <InputLabel id="rangeAgeFloor">Inicio</InputLabel>
                                                    <Select
                                                        labelId="rangeAgeFloor"
                                                        id="rangeAgeFloor"
                                                        value={this.state.rangeAgeFloor}
                                                        onChange={this.AgeFloorChange}
                                                    >
                                                        {rangoEdad}
                                                    </Select>
                                                </FormControl>
                                            </GridItem>
                                            <GridItem >

                                                <FormControl fullWidth>
                                                    <InputLabel id="rangeAgeCeil">Final</InputLabel>
                                                    <Select
                                                        labelId="rangeAgeCeil"
                                                        id="rangeAgeCeil"
                                                        value={this.state.rangeAgeCeil}
                                                        onChange={this.AgeCeilChange}
                                                    >
                                                        {rangoEdad}
                                                    </Select>
                                                </FormControl>

                                            </GridItem>

                                        <GridItem >

                                            <FormControl fullWidth>
                                                <InputLabel id="gender">Selecciona Sexo</InputLabel>
                                                <Select
                                                    labelId="gender"
                                                    id="gender"
                                                    value={this.state.gender}
                                                    onChange={this.GenderChange}
                                                >
                                                    {opcsexo}
                                                </Select>
                                            </FormControl>
                                        </GridItem>

                                    </GridContainer>
                                </GridContainer>

                                <GridContainer
                                    direction="row"
                                    justify="space-around"
                                    alignItems="center">
                                    <GridItem>
                                        <h2>Configuración de experencia </h2>
                                    </GridItem>
                                    <GridContainer
                                        direction="column"
                                        justify="space-evenly"
                                        alignItems="center" xs={12} sm={8} md={6} lg={5}
                                    >

                                        <GridItem>

                                            <GridContainer
                                                direction="row"
                                                justify="space-between"
                                                alignItems="center"
                                            >
                                                <GridItem xs={11}>
                                                    <a id="contained-button-download" name="download" href={this.state.urlDownload} download disabled={!this.state.selectExp}>
                                                        <Button disabled={!this.state.selectExp} type="button" color="dafault">  Descargar recursos graficos originales   &nbsp;&nbsp;&nbsp;  <Archive /></Button>

                                                    </a>
                                                </GridItem>
                                                <GridItem xs={1}>
                                                    <Tooltip
                                                        id="tooltip-left"
                                                        title="Plantilla de recursos que es necesario modificar para Brandear la experiencia."
                                                        placement="top"
                                                        classes={{ tooltip: classes.tooltip }}
                                                    >
                                                        <IconButton >
                                                            <Support />
                                                        </IconButton>


                                                    </Tooltip>
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                        <GridItem >

                                            <GridContainer
                                                direction="row"
                                                justify="space-between"
                                                alignItems="center"
                                            >
                                                <GridItem xs={11}>
                                                    <input id="contained-button-file" type="file" name="file" onChange={this.handleFileUpload} style={{ display: "none" }} disabled={!this.state.selectExp} multiple/>

                                                    <label htmlFor="contained-button-file">
                                                        <Button disabled={!this.state.selectExp} variant="contained" color={ !errorBrand? "danger": "danger" } component="span" >
                                                            Carga los recursos graficos modificados &nbsp;&nbsp;  <Publish />
                                                        </Button>
                                                    </label>
                                                </GridItem>
                                                <GridItem xs={1}>
                                                    <Tooltip
                                                        id="tooltip-left"
                                                        title="Recursos que seran usados para Brandear la experiencia. IMPORTANTE: Subirlos todos de menera individual y no dentro de un comprimido"
                                                        placement="top"
                                                        classes={{ tooltip: classes.tooltip }}
                                                    >
                                                        <IconButton >
                                                            <Support />
                                                        </IconButton>
                                                    </Tooltip>
                                                </GridItem>
                                                {errorBrand && <p style={{ color: "DarkRed" }}>{errorBrand.message}</p>}
                                            </GridContainer>

                                        </GridItem>

                                    <GridItem>

                                            <FormControl fullWidth>
                                                <InputLabel id="TokensType">Tipo de tokens</InputLabel>
                                                <Select
                                                    labelId="TokensType"
                                                    id="TokensType"
                                                    value={this.state.TokensType}
                                                    onChange={this.TokensTypeChange}
                                                >
                                                    {opcTokens}
                                                </Select>
                                            </FormControl>
                                    </GridItem>
                                    <GridItem>

                                            <FormControl fullWidth>
                                                <InputLabel id="TokensMount">Cantidad e tokens</InputLabel>
                                                <Select
                                                    labelId="TokensMount"
                                                    id="TokensMount"
                                                    value={this.state.TokensMount}
                                                    onChange={this.TokensMountChange}
                                                >
                                                    {opcTokensN}
                                                </Select>
                                            </FormControl>
                                        </GridItem>



                                    </GridContainer>

                                    <GridContainer direction="column"
                                        justify="space-evenly"
                                        alignItems="center" xs={12} sm={8} md={6} lg={4}>


                                    </GridContainer>

                                </GridContainer>


                                <GridContainer
                                    direction="row"
                                    justify="space-around"
                                    alignItems="center">
                                    <GridItem>
                                        <h2>Configuracion de Landing page </h2>
                                    </GridItem>
                                    <GridContainer
                                        direction="column"
                                        justify="space-evenly"
                                        alignItems="center" xs={12} sm={8} md={6} lg={5}
                                    >

                                        <GridItem>

                                            <GridContainer
                                                direction="row"
                                                justify="space-between"
                                                alignItems="center"
                                            >
                                                <GridItem xs={11}>
                                                    <a id="contained-button-download" name="download" href={this.state.urlDownloadLand} download disabled={!this.state.selectExp}>
                                                        <Button disabled={!this.state.selectExp} type="button" color="dafault">  Descargar recursos graficos originales   &nbsp;&nbsp;&nbsp;  <Archive /></Button>

                                                    </a>
                                                </GridItem>
                                                <GridItem xs={1}>
                                                    <Tooltip
                                                        id="tooltip-left"
                                                        title="Plantilla de recursos que es necesario modificar para Brandear la Landing page"
                                                        placement="top"
                                                        classes={{ tooltip: classes.tooltip }}
                                                    >
                                                        <IconButton >
                                                            <Support />
                                                        </IconButton>


                                                    </Tooltip>
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                        <GridItem >

                                            <GridContainer
                                                direction="row"
                                                justify="space-between"
                                                alignItems="center"
                                            >
                                                <GridItem xs={11}>
                                                    <input id="contained-button-file2" type="file" name="file" onChange={this.handleFileUpload2} style={{ display: "none" }} disabled={!this.state.selectExp} multiple/>

                                                    <label htmlFor="contained-button-file2">
                                                        <Button disabled={!this.state.selectExp} variant="contained" color={!errorLand ? "danger" : "danger"} component="span">
                                                            Carga los recursos graficos modificados &nbsp;&nbsp;  <Publish />
                                                        </Button>
                                                    </label>
                                                </GridItem>
                                                <GridItem xs={1}>
                                                    <Tooltip
                                                        id="tooltip-left"
                                                        title="Recursos que seran usados para Brandear la Landing page"
                                                        placement="top"
                                                        classes={{ tooltip: classes.tooltip }}
                                                    >
                                                        <IconButton >
                                                            <Support />
                                                        </IconButton>


                                                    </Tooltip>
                                                </GridItem>
                                                {errorLand && <p style={{ color: "DarkRed" }}>{errorLand.message}</p>}
                                            </GridContainer>

                                        </GridItem>

                                        <GridItem>
                                            <GridContainer direction="row"
                                                justify="space-around"
                                                alignItems="center">
                                                <GridItem xs={10}>

                                                    <CustomInput
                                                        labelText="Link de Facebook"
                                                        id="linkRS"

                                                        value={this.state.linkRS}
                                                        formControlProps={{
                                                            fullWidth: true

                                                        }}
                                                        inputProps={{
                                                            onChange: this.onChange,
                                                            disabled: !this.state.checkedFace
                                                        }}
                                                    />

                                                </GridItem>
                                                <GridItem xs={2}>
                                                    <Switch
                                                        checked={this.state.checkedFace}
                                                        onChange={event => this.setState({ checkedFace: event.target.checked })}
                                                        value="checkedA"
                                                        classes={{
                                                            switchBase: classes.switchBase,
                                                            checked: classes.switchChecked,
                                                            thumb: classes.switchIcon,
                                                            iconChecked: classes.switchIconChecked,
                                                            track: classes.switchBar
                                                        }}
                                                    />

                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                        <GridItem>
                                            <GridContainer direction="row"
                                                justify="space-around"
                                                alignItems="center">
                                                <GridItem xs={10}>

                                                    <CustomInput
                                                        labelText="Link de Twitter"
                                                        id="linkRS2"

                                                        value={this.state.linkRS2}
                                                        formControlProps={{
                                                            fullWidth: true

                                                        }}
                                                        inputProps={{
                                                            onChange: this.onChange,
                                                            disabled: !this.state.checkedTwitter
                                                        }}
                                                    />

                                                </GridItem>
                                                <GridItem xs={2}>
                                                    <Switch
                                                        checked={this.state.checkedTwitter}
                                                        onChange={event => this.setState({ checkedTwitter: event.target.checked })}
                                                        value="checkedB"
                                                        classes={{
                                                            switchBase: classes.switchBase,
                                                            checked: classes.switchChecked,
                                                            thumb: classes.switchIcon,
                                                            iconChecked: classes.switchIconChecked,
                                                            track: classes.switchBar
                                                        }}
                                                    />

                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                        <GridItem>
                                            <GridContainer direction="row"
                                                justify="space-around"
                                                alignItems="center">
                                                <GridItem xs={10}>

                                                    <CustomInput
                                                        labelText="Link de Instagram"
                                                        id="linkRS3"

                                                        value={this.state.linkRS3}
                                                        formControlProps={{
                                                            fullWidth: true

                                                        }}
                                                        inputProps={{
                                                            onChange: this.onChange,
                                                            disabled: !this.state.checkedInsta
                                                        }}
                                                    />

                                                </GridItem>
                                                <GridItem xs={2}>
                                                    <Switch
                                                        checked={this.state.checkedInsta}
                                                        onChange={event => this.setState({ checkedInsta: event.target.checked })}
                                                        value="checkedC"
                                                        classes={{
                                                            switchBase: classes.switchBase,
                                                            checked: classes.switchChecked,
                                                            thumb: classes.switchIcon,
                                                            iconChecked: classes.switchIconChecked,
                                                            track: classes.switchBar
                                                        }}
                                                    />

                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                        <GridItem>
                                            <GridContainer direction="row"
                                                justify="space-around"
                                                alignItems="center">
                                                <GridItem xs={10}>

                                                    <CustomInput
                                                        labelText="Link de Whatsapp"
                                                        id="linkRS4"

                                                        value={this.state.linkRS}
                                                        formControlProps={{
                                                            fullWidth: true

                                                        }}
                                                        inputProps={{
                                                            onChange: this.onChange,
                                                            disabled: !this.state.checkedWhats
                                                        }}
                                                    />

                                                </GridItem>
                                                <GridItem xs={2}>
                                                    <Switch
                                                        checked={this.state.checkedWhats}
                                                        onChange={event => this.setState({ checkedWhats: event.target.checked })}
                                                        value="checkedD"
                                                        classes={{
                                                            switchBase: classes.switchBase,
                                                            checked: classes.switchChecked,
                                                            thumb: classes.switchIcon,
                                                            iconChecked: classes.switchIconChecked,
                                                            track: classes.switchBar
                                                        }}
                                                    />

                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                        <GridItem>
                                            <GridContainer direction="row"
                                                justify="space-around"
                                                alignItems="center">
                                                <GridItem xs={10}>

                                                    <CustomInput
                                                        labelText="Link de Discord"
                                                        id="linkRS"

                                                        value={this.state.linkRS5}
                                                        formControlProps={{
                                                            fullWidth: true

                                                        }}
                                                        inputProps={{
                                                            onChange: this.onChange,
                                                            disabled: !this.state.checkedDiscord
                                                        }}
                                                    />

                                                </GridItem>
                                                <GridItem xs={2}>
                                                    <Switch
                                                        checked={this.state.checkedDiscord}
                                                        onChange={event => this.setState({ checkedDiscord: event.target.checked })}
                                                        value="checkedF"
                                                        classes={{
                                                            switchBase: classes.switchBase,
                                                            checked: classes.switchChecked,
                                                            thumb: classes.switchIcon,
                                                            iconChecked: classes.switchIconChecked,
                                                            track: classes.switchBar
                                                        }}
                                                    />

                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>


                                    </GridContainer>
                                    <GridContainer direction="column"
                                        justify="space-evenly"
                                        alignItems="center" xs={12} sm={8} md={6} lg={4}>

                                        <GridItem>

                                                <input id="contained-button-file3" type="file" name="file" onChange={this.handleFileUpload3} style={{ display: "none" }} />

                                                <label htmlFor="contained-button-file3">
                                                    <Button variant="contained" color="danger" component="span">
                                                        Carga archivo de legales &nbsp;&nbsp;  <Publish />
                                                    </Button>
                                                </label>

                                        </GridItem>
                                        <GridItem>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="textSocial"
                                                    label="Descripción-Invitación"
                                                    multiline
                                                    rows={4}
                                                    defaultValue="Default Value"
                                                    onChange={this.onChange}
                                                    value={this.state.textSocial}
                                                />
                                            </FormControl>
                                        </GridItem>

                                    </GridContainer>


                                </GridContainer>

                                <GridContainer
                                    direction="row-reverse"
                                    justify="flex-start"
                                    alignItems="center"
                                    xs={12}
                                >
                                    <GridItem xs={4} sm={3} md={2} lg={1} xl={1}>
                                        <br/>
                                        <br/>
                                        <Button disabled={isInvalid} color="danger" type="submit">Activar</Button>


                                    </GridItem>

                                </GridContainer>
                                {error && <p>{error.message}</p>}
                            </CardBody>
                        </form>
                    </Card>
                )}
            </AuthUserContext.Consumer>
                );
  }
}
const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Regitrar</Link>
  </p>
);

const condition = authUser =>
    authUser && (authUser.rol == ROLES.ADMIN || authUser.rol == ROLES.BRAND);//[ROLES.ADMIN];


const ActiveCampainsForm = compose(
    withAuthorization(condition),
    withStyles(styles),
    withRouter,
    withFirebase,
)(ActiveCampainsFormBase);


export default ActiveCampain;
export { ActiveCampainsForm, SignUpLink };
