import React, { Component } from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import *as ROUTES from '../../constants/routes';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Primary from "components/Typography/Primary.js";

import { withFirebase } from '../Firebase';
import { withAuthorization, AuthUserContext } from '../Session';
import * as ROLES from '../../constants/roles';
import Button from 'components/CustomButtons/Button';
import CardCamp from '../Campains/CardCamp2';
import Divider from '@material-ui/core/Divider';

import title from './img/nueva-campaña.png';
import _ from 'lodash'



class ClientesDash extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            campains: [],
            uid: "",
            void:false,
        };
    }
    componentDidMount(auth) {
        this.setState({ loading: true });
        //ref.orderByChild("height").equalTo(25).on("child_added", function (snapshot) {
        //    console.log(snapshot.key);
        //});
        console.log("AQUI");
        console.log(this.props.idC.uid);
        this.props.firebase.campains(this.props.idC.uid).on('value', snapshot => {

            const campainsObject = snapshot.val();
            console.log(campainsObject);
            if (campainsObject) {
                const campainsList = Object.keys(campainsObject).map(key => ({
                    ...campainsObject[key],
                    uid: key,
                    urlImg: "",
                }));


                this.setState({
                    campains: campainsList,
                    loading: false,
                    creado: true,
                });
            } else {
                this.setState({
                    void: true,
                    loading: false,
                })
            }
        });

      //  this.props.firebase.getImagBrand0(idC, campain.uid, campain.branding.file0)

    }

    componentWillUnmount() {
        this.props.firebase.experience().off();
    }
    render() {
        const { campains, loading } = this.state;
        return (

            <GridContainer
                direction="column"
                justify="space-around"
                alignItems="stretch"

            >
                <GridContainer >
                    <GridItem>
                        <Primary> <h1><b>Campa&ntilde;as</b></h1></Primary>
                        <Divider variant="middle " />
                                {loading && <div>Loading ...</div>}
                    </GridItem>
                </GridContainer >
                <GridContainer

                    direction="row-reverse"
                    justify="flex-start"
                    alignItems="baseline"
                >
                    {/*
                    <GridItem xs={3}>

                            <Link  to={ROUTES.ACTIVECAMPAIN}>
                            <Button type="button" size="lg" color="danger"> Campa&ntilde;a Nueva</Button>
                            </Link>
                        <br/><br/>
                    </GridItem>
                    */}
                </GridContainer>
                {this.state.void ?
                    <GridContainer

                        direction="column"
                        justify="center"
                        alignItems="center"
                    >

                            <img  src={title} />


                    </GridContainer>
                    :
                    <CampCards campains={campains} idC={this.props.idC.uid} nameC={this.props.idC.username} />
                }


            </GridContainer>


        );
    }
}

const CampCards = ({ campains, idC, nameC }) => (

    <GridContainer

        direction="row"
        justify="flex-start"
        alignItems="baseline"
    >
        {campains.map(campain => (
            <GridItem xs={12} sm={6} md={3}>
                <CardCamp idCliente={idC} nameCliente={nameC} experience={campain.uidExp} idCamp={campain.uid} name={campain.expName} link={campain.link} status={campain.status} img={campain.branding.file0} />
            </GridItem>
        ))}
   </GridContainer>
);

const condition = authUser =>
    authUser && authUser.rol == ROLES.BRAND;//[ROLES.ADMIN];
export default compose(
    withAuthorization(condition),
    withFirebase)
    (ClientesDash);
