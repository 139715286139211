import React, { Component } from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import *as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';

import * as ROLES from '../../constants/roles';


import CircularProgress from '@material-ui/core/CircularProgress';
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import { withStyles } from '@material-ui/core/styles';


import _ from 'lodash'
import { func } from 'prop-types';



const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
};

const INITIAL_STATE = {

    resultado:"Canjeo en proceso",
    loading: false,
    cargado: false,
};

class ClientProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE
        };
    }



    componentDidMount() {
        this.setState({ loading: true });


        let validated = false;
        //proceso de valiadar cupon.
        validated = true;
        //
        console.log("IDexp: " + this.props.match.params.id + "  cupon: " + this.props.match.params.token + "  idUSER: " + this.props.match.params.idUser);


        if (validated)

            this.props.firebase.coupons(this.props.match.params.idUser, this.props.match.params.id).once('value', snapshot => {

                const couponObject = snapshot.val();
                console.log(couponObject);
                if (couponObject) { // si el cupon ya tiene un cupon de la actividad
                    this.setState({
                        resultado: "Ya has pedido el cupon antes",
                        cargado: true,
                    })
                }
                else { // si no ha canjeado cupon de la experiencia

                    this.props.firebase.experienceNCoupons(this.props.match.params.id).once('value', snapshot => {
                        const NCoupons = snapshot.val();
                        console.log("numero Cupones: " + NCoupons + " Estado:  " + this.state.cargado);
                        if (NCoupons > 0) {

                            var coupons = this.props.match.params.token;
                            this.setState({
                                resultado: "Cupon canjeado con exito",
                                cargado: true,
                            });
                            // cupones dados por dia +1

                            this.props.firebase.experienceNCouponsToday(this.props.match.params.id).transaction(function (n) {
                                console.log("Cupon++");
                                if (n) {
                                    n = n + 1;
                                } else n = 1;
                                return (n);
                            });

                            // cupones totales -1
                            this.props.firebase.experienceNCouponsTotal(this.props.match.params.id).transaction(function (n) {
                                console.log("Cupon--");
                                if (n) {
                                    n = n - 1;
                                }
                                return n;
                            })
                            // actualizar cupones al usuario
                            this.props.firebase
                                .coupons(this.props.match.params.idUser, this.props.match.params.id)
                                .set({
                                    coupons

                                }).then(() => { console.log("update"); });

                            this.props.firebase
                                .lastPlay(this.props.match.params.idUser, this.props.match.params.id).once("value", snapshot => {
                                    console.log(snapshot.val());
                                    const plays = snapshot.val();
                                    const playsList = Object.keys(plays).map(key => ({
                                        uid: key,

                                    }));
                                    console.log(playsList);
                                    this.props.firebase
                                        .coupons2(this.props.match.params.idUser, this.props.match.params.id, playsList[0].uid)
                                        .update({
                                            coupons

                                        });
                            })
                        } else {
                            this.setState({
                                resultado: "Se han agotado la promocion",
                                cargado: true,
                            });
                        }


                    });


                }
            })
        else
            this.setState({
                resultado: "El cupon no es valido",
                cargado:true,
            })

    }

    componentWillUnmount() {
        this.props.firebase.experienceNCoupons().off();
    }


    render() {
        const { users, loading ,cargado} = this.state;
        const { classes } = this.props;
        return (
            <div>


                <GridContainer
                                    direction="row"
                                    justify="space-around"
                                    alignItems="flex-start">
                                    <GridItem xs={12} lg={5}>
                                    <Card >
                                        <CardHeader>
                                          <CardIcon color="danger">
                                            <h4 className={classes.cardTitleWhite}>Canjear cupon</h4>
                                            <p className={classes.cardCategoryWhite}>Datos de registro</p>
                                          </CardIcon>
                                        </CardHeader>

                                        <CardBody>
                                            <GridContainer container
                                                direction="column"
                                                justify="space-around"
                                                alignItems="center" xs={12}>
                                                {!this.state.cargado &&
                                                    <GridContainer direction="row"
                                                        justify="center"
                                                        alignItems="stretch" >
                                                        <GridItem xs={12}>
                                                            <CircularProgress color="primary" size={"100%"} />
                                                        </GridItem>
                                                    </GridContainer>
                                                }
                                                {this.state.resultado}

                                            </GridContainer>
                                        {this.state.cargado &&
                                        < Link to={ROUTES.HOME + "/"+this.props.match.params.id}>
                                            <Button type="button" size="lg" color="danger"> Continuar</Button>
                                        </Link>
                                        }
                                        </CardBody>
                                        </Card>
                                    </GridItem>



                                </GridContainer>


         </div>

        );
    }
}



export default compose(

    withStyles(styles),
    withFirebase)
    (ClientProfile);
