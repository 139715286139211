// JavaScript source code
import React, { Component } from 'react';
//import { Button, Card, Image } from 'semantic-ui-react'


// material-ui components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";

import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";


import Table from "@material-ui/core/Table";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash'

import { cardTitle } from "assets/jss/material-kit-react.js";

import * as ROLES from '../../constants/roles';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import { compose } from 'recompose';
import styles2 from "assets/jss/material-dashboard-react/components/tableStyle.js";

const styles = {
    cardTitle,
};

const useStyles = makeStyles(styles);



class TablePlays extends Component {


    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };

    constructor(props) {
        super(props);

        this.close = this.close.bind(this);

        this.state = {
            contenido: [],
            open: false,
            size: 'tiny',
            nameModal: "",
            idModal: "",
            indiceModal: "",
            startDate: new Date(),
            tipoB: 1,
            espacio: 0,
            paginas: 0,
            pagN: 0,
            tamFilas: 5,
            inicio: true,
            pays: [],
            loading: false,
            TamFilasArray: [],
        }


    }

    componentDidMount() {
        this.setState({ loading: true });
        //console.log(this.props.id);

        const usersObject = this.props.user;
        const idCamp = this.props.idCamp;
            console.log(usersObject);
            if (usersObject.campPlay) {
                const expList = [];
                const objPlays = usersObject.campPlay;

                const campList = Object.keys(objPlays).map(key => ({
                    ...objPlays[key],
                    uid:key,
                }));
                var playsList = [];
                for (let i = 0; i < campList.length; i++) {
                    if (campList[i].uid == idCamp) {
                        console.log(campList[i].Plays);
                        playsList = Object.keys(campList[i].Plays).map(key => ({

                            date: key,
                            score: campList[i].Plays[key].Score,
                            coupon: campList[i].Plays[key].coupons,
                        }));
                    }
                }

                console.log(playsList);


                playsList = _.compact(playsList);

                console.log(playsList);
                let np = Math.ceil(playsList.length + 10 / this.state.tamFilas);

                console.log("np:  " +np);
                let restan = (this.state.tamFilas - (playsList.length - ((np - 1) * this.state.tamFilas)));
                console.log("vacios=  " + restan);
                if (playsList.length == 0) restan = this.state.tamFilas;

                if (playsList.length % this.state.tamFilas < this.state.tamFilas)
                    for (let j = 0; j < restan; j++) {
                        const vacia2 = {
                            date: "", score: "", coupon: ""
                            //cellExp: <Table.Cell singleLine>  </Table.Cell>,
                            //cellClient: <Table.Cell singleLine>  </Table.Cell>,
                        }
                        playsList.push(vacia2)

                    }
                let filasTam = [5];
                if (playsList.length > 10) filasTam = [5, 10];
                if (playsList.length > 25) filasTam = [5, 10, 25];
                if (playsList.length > 100) filasTam = [5, 10, 25, 100];

                //console.log( expList );

                this.setState({
                    plays: playsList,
                    loading: false,
                    paginas: np,
                    extraVoid: restan,
                    TamFilasArray: filasTam,
                }, () => this.setState({
                    open: false,
                    contenido: this.TablaInicial(0),
                    open: true,
                    inicio: false,

                }));



            }

    }


    show(e, id) {

        this.setState({
            open: true,
            idModal: id,

        }, () => {
            this.setState({
                open: true,
                contenido: this.TablaInicial(this.state.pagN),

            })
        })
    }
    close() {
        this.setState({
            open: false,
        }
            , () => {
                this.setState({
                    open: false,
                    contenido: this.TablaInicial(this.state.pagN),
                    open: true,
                })
            }
        );
    }





    handleChangePage = (event, newPage) => {
        this.Paginacion(newPage);
    };

    handleChangeRowsPerPage = (event) => {

        this.setState({ tamFilas: parseInt(event.target.value, 10) });
        // setRowsPerPage(parseInt(event.target.value, 10));
        this.Paginacion(0);
    };



    Paginacion = (num) => {
        let pag = num;

        this.setState({
            open: false,
            pagN: pag,
        }, () => {
            this.setState({
                open: false,
                contenido: this.TablaInicial(pag),
                open: true,
            })
        })
    }


    TablaInicial = (pagina) => {
        let content = [];
        let styles = { fontSize: "12px" };
        const { classes } = this.props;


        content.push(
            <div className={classes.tableResponsive}>


                <Table size="small" className={classes.table} >
                    <TableHead className={classes["danger" + "TableHeader"]}>
                        <TableRow className={classes.tableHeadRow}>

                            <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>Fecha</TableCell>
                            <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>Score</TableCell>
                            <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>Cupon</TableCell>

                        </TableRow>
                    </TableHead>


                    {this.createTable(pagina)}


                </Table>
                <TablePagination
                    rowsPerPageOptions={this.state.TamFilasArray}
                    component="div"
                    count={this.state.plays.length}
                    rowsPerPage={this.state.tamFilas}
                    page={this.state.pagN}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}

                />


            </div>
        )
        return content;
    }
    createTable = (pagina) => {
        let styles = { fontSize: "10px", };
        var i = 0;
        let filas = [];

        var np = pagina * this.state.tamFilas;
        //console.log(this.state.users);
        for (i = np; i < (np + this.state.tamFilas); i++) {
            // console.debug("indice: "+i);
            //const nombre = tabla[i].alumno_nombre + " " + tabla[i].alumno_apellidop + " " + tabla[i].alumno_apellidom;
            let d = moment(parseInt(this.state.plays[i].date)).format('MMMM Do YYYY, h:mm:ss a');

            var cab =
                <TableRow  >
                    <TableCell singleLine>{d}</TableCell>
                    <TableCell singleLine>{this.state.plays[i].score}</TableCell>
                    <TableCell singleLine>{this.state.plays[i].coupon}</TableCell>
                </TableRow >;


            filas.push(
                <TableBody>
                    {cab}
                </TableBody>
            )
        }
        return filas;

    }




    render() {

        return (
            <div>

                {this.state.contenido}
            </div>
        )
    }



}

const Tabla= compose(
    withStyles(styles2),
    )
    (TablePlays);
function SampleFab(props) {
    const classes = useStyles();
    return (

        <div>
            {props.loading == true ?
                <Card >
                    <CardHeader color="info">load</CardHeader>
                    <GridContainer direction="row"
                        justify="center"
                        alignItems="stretch" >
                        <GridItem xs={12}>
                            <CircularProgress color="primary" size={"100%"} />
                        </GridItem>
                    </GridContainer>
                </Card >
                :

                <Card >
                    <CardHeader color="danger">Jugador</CardHeader>
                    <CardBody>
                        <h4 className={classes.cardTitle}>{props.user.username}</h4>
                        <p>
                            {props.user.email}
                        </p>

                        <Tabla user={props.user} idCamp={props.idCamp} />

                    </CardBody>
                </Card >
            }

        </div>

    );
}

class target extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,

        };
    }


    componentDidMount() {
        this.setState({
            loading: true,
            camp: { name: "" },
        });
        console.log("uid  " + this.props.id);

        this.props.firebase.user(this.props.id).once
            ('value', (camp) => {
                console.log(camp.val().username);
                this.setState({
                    user: camp.val(),
                    loading: false,
                });

            });
    }

    componentWillUnmount() {
        this.props.firebase.user().off();
    }


    render() {

        return (
            <div>

                <SampleFab user={this.state.user} loading={this.state.loading} idCamp={this.props.idCamp} />
            </div>
        );


    }
}
const condition = authUser =>
    authUser && authUser.rol == ROLES.TARGET;//[ROLES.ADMIN];
export default compose(
    withAuthorization(condition),
    withFirebase)
    (target);
