import React, { Component } from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import *as ROUTES from '../../constants/routes';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Primary from "components/Typography/Primary.js";
import Divider from '@material-ui/core/Divider';

import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import * as ROLES from '../../constants/roles';
import TablaPays from './TablaPaysAdmi';


import Button from 'components/CustomButtons/Button';


import _ from 'lodash'



class Clientes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            users: [],
        };
    }

    render() {
        const { users, loading } = this.state;
        return (


            <GridContainer
                direction="column"
                justify="space-around"
                alignItems="stretch"

            >
                <GridContainer >
                    <GridItem>
                        <Primary> <h1><b>Pagos</b></h1></Primary>
                        <Divider variant="middle " />
                        {loading && <div>Loading ...</div>}
                    </GridItem>
                </GridContainer >


                <TablaPays />

            </GridContainer>


        );
    }
}



const condition = authUser =>
    authUser && authUser.rol == ROLES.ADMIN;//[ROLES.ADMIN];
export default compose(
    withAuthorization(condition),
    withFirebase)
    (Clientes);
