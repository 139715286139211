import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { compose } from 'recompose';


import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import { withStyles } from '@material-ui/core/styles';

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
};



const PasswordForgetPage = () => (
    <div>
        <h1>PasswordForget</h1>
        <PasswordForgetForm />
    </div>
);
const INITIAL_STATE = {
    email: '',
    error: null,
};
class PasswordForgetFormBase extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }
    onSubmit = event => {
        const { email } = this.state;
        this.props.firebase
            .doPasswordReset(email)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
            })
            .catch(error => {
                this.setState({ error });
            });
        event.preventDefault();
    };
    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    render() {
        const { email, error } = this.state;
        const isInvalid = email === '';
        const { classes } = this.props;
        return (
            <form onSubmit={this.onSubmit}>

                <GridContainer xs={12} sm={10} md={8}>
                    <Card >
                        <CardHeader>
                          <CardIcon color="danger">
                            <h4 className={classes.cardTitleWhite}>Clave olvidada</h4>
                            <p className={classes.cardCategoryWhite}>Registra los datos</p>
                          </CardIcon>
                        </CardHeader>

                        <CardBody>
                            <GridContainer container
                                direction="row"
                                justify="space-around"
                                alignItems="center" xs={12}>

                                <GridItem>

                                    <CustomInput
                                        labelText="Email Address"

                                        value={this.state.email}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            onChange: this.onChange,
                                            type: "text",
                                            name: "email"
                                        }}
                                    />
                                </GridItem>

                                <Button color="danger"disabled={isInvalid} type="submit">
                                    Reset My Password
                                </Button>
                                {error && <p>{error.message}</p>}
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridContainer>
            </form>
        );
    }
}
const PasswordForgetLink = () => (
    <p>
        <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
    </p>
);
export default PasswordForgetPage;
const PasswordForgetForm = compose(
    withStyles(styles),
    withFirebase,
)(PasswordForgetFormBase);




export { PasswordForgetForm, PasswordForgetLink };
