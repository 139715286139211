import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';


import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import { withStyles } from '@material-ui/core/styles';
import { withAuthorization } from '../Session';

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from '@material-ui/core/IconButton';

import Publish from "@material-ui/icons/Publish";
import Support from '@material-ui/icons/ContactSupport';

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
};



const RegistarCampain = () => (
  <div>


        <RegistarCampainsForm/>
  </div>
);

const INITIAL_STATE = {
    name: '',
    link: '',
    description: '',
    image: '',
    stats: {nGamesTotal:0},
    error: null,
    Status: "Active",
    selectBrand: false,
    branding: "",
    Resources: "",
    brandingLand: "",
    selectBrandL: false,
    ResourcesLand: "",
    selectResourcesL: false,
    selectResources: false,
    selectImage: false,
};


class RegistarCampainsFormBase extends Component {
  constructor(props) {
      super(props);
      this.state = { ...INITIAL_STATE }
  }
    onSubmit = event => {
        const { name, link, description, image, stats, Status, branding, Resources, ResourcesLand, brandingLand } = this.state;
         this.props.firebase
            .experiences()
            .push({
                name,
                link,
                description,
                image:image.name,
                stats,
                Status,
                branding,
                brandingLand
            }).then((snap) => {
                var uploadTask = this.props.firebase.campaingResources(snap.key).put(Resources);
                var uploadTask2 = this.props.firebase.campaingResourcesLand(snap.key).put(ResourcesLand);
                var uploadTask3 = this.props.firebase.setGameImg(image.name).put(image);
            }).then(() => {
                this.setState({ ...INITIAL_STATE });

                this.props.history.push(ROUTES.CAMPAINS);

            })
            .catch(error => {
                this.setState({ error });
            });
        event.preventDefault();
  }
    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    handleFileUpload = e => { //registra los nombres de los recursos
        console.log(e.target.files);
        const campN =[];
        for (let i = 0; i < e.target.files.length; i++) {
            campN[i] = e.target.files[i].name;
            //campN.push({ ["file" + i]: e.target.files[i].name });
        }

        console.log(campN);
        this.setState({
            branding: campN,
            selectBrand: true,
        });
    }


    handleFileUpload2 = e => {// registra el recurso para descargar

        const file = e.target.files[0];
        this.setState({

            Resources:  file ,
            selectResources: true,
        });
    }

    handleFileUpload3 = e => {// registra imagen
        console.log(e.target.files[0]);
        const file = e.target.files[0];

        this.setState({
            image: file,
            selectImage: true,
        });
    }


    handleFileUpload4 = e => { //registra los nombres de los recursos
        console.log(e.target.files);
        const campN = [];
        for (let i = 0; i < e.target.files.length; i++) {
            campN[i] = e.target.files[i].name;
            //campN.push({ ["file" + i]: e.target.files[i].name });
        }

        console.log(campN);
        this.setState({
            brandingLand: campN,
            selectBrandL: true,
        });
    }


    handleFileUpload5 = e => {// registra el recurso para descargar

        const file = e.target.files[0];
        this.setState({

            ResourcesLand: file,
            selectResourcesL: true,
        });
    }


    render() {
        const {
            name,
            link,
            description,
            image,

            error
        } = this.state;

        const isInvalid =
            this.state.name === "" ||
            this.state.selectBrand === false ||
            this.state.selectImage === false ||
            this.state.selectResources === false;


        const { classes } = this.props;
        return (


             <form onSubmit={this.onSubmit}>

                  <GridContainer xs={12} sm={10} md={8}>
                        <Card >
                            <CardHeader>
                              <CardIcon color="danger">
                                <h4 className={classes.cardTitleWhite}>Registro de experiencias</h4>
                                <p className={classes.cardCategoryWhite}>Registra los datos</p>
                              </CardIcon>
                            </CardHeader>

                            <CardBody>
                                <GridContainer
                                    direction="column"
                                    justify="center"
                                    alignItems="center" xs={12}>

                                    <GridItem>
                                        <CustomInput
                                            labelText="Nombre de la experiencia"
                                            value={name}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: this.onChange,
                                                type: "text",
                                                name: "name"
                                            }}
                                        />
                                    </GridItem>

                                    <GridItem>
                                        <CustomInput
                                            labelText="Link de la experiencia"
                                            value={link}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: this.onChange,
                                                type: "text",
                                                name: "link"
                                            }}
                                        />
                                    </GridItem>

                                    <GridItem>
                                        <CustomInput
                                            labelText="Descripcion"
                                            value={description}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: this.onChange,
                                                type: "text",
                                                name: "description"
                                            }}
                                        />
                                    </GridItem>


                                    <GridItem xs={12} md={8} lg={6}>

                                        <GridContainer
                                            direction="row"
                                            justify="space-between"
                                            alignItems="center"
                                        >
                                            <GridItem xs={11}>
                                                <input id="contained-button-file" type="file" name="file" onChange={this.handleFileUpload} style={{ display: "none" }} multiple/>

                                                <label htmlFor="contained-button-file">
                                                    <Button variant="contained" color="danger" component="span">
                                                        Carga los recursos graficos base &nbsp;&nbsp;  <Publish />
                                                    </Button>
                                                </label>
                                            </GridItem>
                                            <GridItem xs={1}>
                                                <Tooltip
                                                    id="tooltip-left"
                                                    title="Recursos necesarios para brandear la experiencia, tienen que subirse todo juntos pero en archivos separados(no .rar, etc.)"
                                                    placement="top"
                                                    classes={{ tooltip: classes.tooltip }}
                                                >
                                                    <IconButton >
                                                        <Support />
                                                    </IconButton>


                                                </Tooltip>
                                            </GridItem>
                                        </GridContainer>

                                    </GridItem>

                                    <GridItem  xs={12} md={8} lg={6}>

                                        <GridContainer
                                            direction="row"
                                            justify="space-between"
                                            alignItems="center"
                                        >
                                            <GridItem xs={11}>
                                                <input id="contained-button-file2" type="file" name="file" onChange={this.handleFileUpload2} style={{ display: "none" }} />

                                                <label htmlFor="contained-button-file2">
                                                    <Button variant="contained" color="danger" component="span">
                                                        Carga comprimido de recursos graficos base <Publish />
                                                    </Button>
                                                </label>
                                            </GridItem>
                                            <GridItem xs={1}>
                                                <Tooltip
                                                    id="tooltip-left"
                                                    title="Compromido de los archivos necesarios para brandear la experiencia, deben ser los mismos que los del campo anterior"
                                                    placement="top"
                                                    classes={{ tooltip: classes.tooltip }}
                                                >
                                                    <IconButton >
                                                        <Support />
                                                    </IconButton>


                                                </Tooltip>
                                            </GridItem>
                                        </GridContainer>

                                </GridItem>


                                <GridItem xs={12} md={8} lg={6}>

                                    <GridContainer
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center"
                                    >
                                        <GridItem xs={11}>
                                            <input id="contained-button-file4" type="file" name="file" onChange={this.handleFileUpload4} style={{ display: "none" }} multiple />

                                            <label htmlFor="contained-button-file4">
                                                <Button variant="contained" color="danger" component="span">
                                                    Carga los recursos graficos base de la landing page&nbsp;&nbsp;  <Publish />
                                                </Button>
                                            </label>
                                        </GridItem>
                                        <GridItem xs={1}>
                                            <Tooltip
                                                id="tooltip-left"
                                                title="Recursos necesarios para brandear la landing page, tienen que subirse todo juntos pero en archivos separados(no .rar, etc.)"
                                                placement="top"
                                                classes={{ tooltip: classes.tooltip }}
                                            >
                                                <IconButton >
                                                    <Support />
                                                </IconButton>


                                            </Tooltip>
                                        </GridItem>
                                    </GridContainer>

                                </GridItem>

                                <GridItem xs={12} md={8} lg={6}>

                                    <GridContainer
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center"
                                    >
                                        <GridItem xs={11}>
                                            <input id="contained-button-file5" type="file" name="file" onChange={this.handleFileUpload5} style={{ display: "none" }} />

                                            <label htmlFor="contained-button-file5">
                                                <Button variant="contained" color="danger" component="span">
                                                    Carga comprimido de recursos graficos base de la landing page <Publish />
                                                </Button>
                                            </label>
                                        </GridItem>
                                        <GridItem xs={1}>
                                            <Tooltip
                                                id="tooltip-left"
                                                title="Compromido de los archivos necesarios para brandear la landing page, deben ser los mismos que los del campo anterior"
                                                placement="top"
                                                classes={{ tooltip: classes.tooltip }}
                                            >
                                                <IconButton >
                                                    <Support />
                                                </IconButton>


                                            </Tooltip>
                                        </GridItem>
                                    </GridContainer>

                                </GridItem>


                                    <GridItem  xs={12} md={8} lg={6}>

                                    <GridContainer
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center"
                                    >
                                        <GridItem xs={11}>
                                            <input id="contained-button-file3" type="file" name="file" onChange={this.handleFileUpload3} style={{ display: "none" }} />

                                            <label htmlFor="contained-button-file3">
                                                <Button variant="contained" color="danger" component="span">
                                                    Carga Portada  <Publish />
                                                </Button>
                                            </label>
                                        </GridItem>
                                        <GridItem xs={1}>
                                            <Tooltip
                                                id="tooltip-left"
                                                title="Imagen que sera usada para mostrar en la Tarjeta de la experiencia"
                                                placement="top"
                                                classes={{ tooltip: classes.tooltip }}
                                            >
                                                <IconButton >
                                                    <Support />
                                                </IconButton>


                                            </Tooltip>
                                        </GridItem>
                                    </GridContainer>

                                </GridItem>
                                <Button disabled={isInvalid} color="danger"  type="submit">
                                       Registrar
                                </Button>
                                    {error && <p>{error.message}</p>}
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridContainer>

      </form>

                );
  }
}
const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Regitrar</Link>
  </p>
);

const condition = authUser =>
    authUser && (authUser.rol == ROLES.ADMIN);//[ROLES.ADMIN];

const RegistarCampainsForm = compose(
    withRouter,
    withFirebase,
    withStyles(styles),
    withAuthorization(condition),
)(RegistarCampainsFormBase);


export default RegistarCampain;
export { RegistarCampainsForm, SignUpLink };
