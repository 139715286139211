import React, { Component } from 'react';
import { withAuthorization } from '../Session';
import { AuthUserContext } from '../Session';
import * as ROLES from '../../constants/roles';
import TargetMain from './target'
import { auth } from 'firebase';


class HomeStadistics extends Component{
    constructor(props) {
        super(props);

    }
    render() {
        return (
            <div>
                <h1>Home</h1>
                <p>The Home Page is accessible by every signed in user.</p>
                <AuthUserContext.Consumer>

                    {authUser =>
                        <div>
                            {
                                authUser.rol == ROLES.TARGET &&
                                <div>
                                    target
                                <TargetMain id={authUser.uid} idCamp={this.props.match.params.id} />
                                </div>
                            }
                            {
                                authUser.rol == ROLES.ADMIN &&
                                <div>
                                    ADMIN
                        </div>

                            }
                            {
                                authUser.rol == ROLES.BRAND &&
                                <div>
                                    Brand
                        </div>
                            }
                        </div>


                    }


                </AuthUserContext.Consumer>
            </div>
        );
    };

}


const condition = authUser => !!authUser;
export default withAuthorization(condition)(HomeStadistics);
