import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import { AuthUserContext } from '../Session';
import * as ROLES from '../../constants/roles';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Primary from "components/Typography/Primary.js";
import CardGame from './CardGame';
import Divider from '@material-ui/core/Divider';
import { compose } from 'recompose';
import { auth } from 'firebase';


class HomePage extends Component{
    constructor(props) {
        super(props);
        this.state = {
            games : [],
            loading : true
        };
    }

    componentDidMount(auth) {
        this.props.firebase.experiences().on('value', snapshot => {
          const gameObject = snapshot.val();
          if (gameObject) {
              const gameList = Object.keys(gameObject).map(key => ({ ...gameObject[key] }))

              const expList = [];
              let i = 0;
              for (i = 0; i < gameList.length; i++) {
                  if (gameList[i].Status == "Active")
                      expList.push(gameList[i]);
              }

              this.setState({
                  games: expList,
                  loading: false,
              });
          }
          else {
              this.setState({loading: false})
          }
        });
    }

    render() {
      const { games, loading } = this.state;
        return (
          <GridContainer
              direction="column"
              justify="space-around"
              alignItems="stretch"
               >

              <GridContainer>
                  <GridItem>
                      <Primary ><h1><b>Juegos</b></h1></Primary>
                      <Divider variant="middle " />
                      {loading && <div>Loading ...</div>}
                      <br/>
                      <Primary><h3><b>Conoce el catalogo de nuestros juegos, ¡completamente customizable!</b></h3></Primary>
                      <br/>
                  </GridItem>
              </GridContainer >
              <GameCards games={games} />
          </GridContainer>
        );
    };
}


const GameCards = ({ games }) => (
    <GridContainer
        direction="row"
        justify="flex-start"
        alignItems="baseline">
        {games.map(game => (
            <GridItem xs={12} sm={6} md={3}>
                <CardGame name={game.name} link={game.link} description={game.description} img={game.image} />
            </GridItem>
        ))}
   </GridContainer>
);

//const condition = authUser => !!authUser;
export default compose(withFirebase)(HomePage);
