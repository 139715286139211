import React from 'react';
import logo from './img/logo.png';
import grupo from './img/grupo.png';
import Iframe from 'react-iframe'
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from 'components/CustomButtons/Button';
import Typography from '@material-ui/core/Typography';


import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';
import AccessTime from "@material-ui/icons/AccessTime";
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import TimelineIcon from '@material-ui/icons/Timeline';


import * as ROUTES from '../../constants/routes';
import { Link, withRouter, useParams } from 'react-router-dom';
import { compose } from 'recompose';

const logoStyle = {
    width: 302, height: 136, marginTop: "10px",
  marginBottom: "10px",
  marginRight: "30px",
  marginLeft: "30px",
}
const grupoStyle = {
    width: 525, height: 312
}
const redStyle= { fontSize: 25, color: "#f44336", width: "100%" }
const redIcons= { fontSize: 80, color: "#f44336"}
const Landing = () => (
    <GridContainer
        direction="column"
        justify="flex-start"
        alignItems="stretch">
        <GridItem style={{ backgroundColor: "#ffffff" }}>
            <GridContainer direction="row"
                justify="flex-end"
                alignItems="center">
                <GridItem xs={8} sm={8} md={6} lg={5} xl={4}>
                    <img style={logoStyle} src={logo} />

                </GridItem>
                <GridItem xs={4} sm={4} md={2} lg={3} xl={5}>

                </GridItem>
                <GridItem xs={3} sm={1} md={1} lg={1} xl={1}>

                    <Link to={ROUTES.SIGN_IN}><b>Login</b></Link>
                </GridItem>
                {/*
                <GridItem xs={7} sm={4} md={3} lg={3} xl={2}>
                    <Link to={ROUTES.SIGN_UP}>
                        <Button type="button" size="lg" color="danger"> Registrate gratis</Button>
                    </Link>

                </GridItem>
                */}
            </GridContainer>
        </GridItem>


        <GridItem style={{ backgroundColor:"#34163a"}}>
            <br /><br />
            <Typography align="center" style={{ color: "#777c88" ,fontSize: "25px"}}>
                &iquest;La situaci&oacute;n mundial actual te hace perder audiencia?
            </Typography>
            <br/>
        </GridItem>
        <GridItem alignContent="center" xs={12} style={{ backgroundColor: "#34163a" }}>

            <Typography align="center" style={{ color: "#777c88", fontSize: "25px"}}>
                &iquest;Buscas nuevas maneras digitales de llegar a m&aacute;s audiencia?
            </Typography>
            <br /><br />
        </GridItem>
        <GridItem style={{ backgroundColor: "#34163a" }}>

            <Iframe url="https://cdn.flipsnack.com/widget/v2/widget.html?hash=zk5gjkt4ot"
                width="100%"
                height="480px"
                id="myId"
                className="myClassname"
                display="initial"
                frameBorder ="0"
                position="relative" />
        </GridItem>



        <GridItem style={{ backgroundColor: "#ffffff" }}>
            <GridContainer direction="row"
                justify="space-evenly"
                alignItems="center">
                <GridItem xs={12} sm={8} md={4} lg={4} xl={4}>
                    <Typography style={redStyle} aling="center">
                        &iquest;Qu&eacute; es Game Campaigns?
                    </Typography>
                    <Typography variant="body1" aling="center">
                        <br/>
                        Una nueva y divertida manera de customizar y crear experiencias l&uacute;dicas enfocadas exclusivamente para tu audiencia.
                    </Typography>
                    <br/>
                    <Typography variant="body1" aling="center">
                        Ideal para marcas, agencias o PyMEs.
                    </Typography>


                </GridItem>
                <GridItem xs={12} sm={8} md={8} lg={6} xl={4}>
                    <br/><br/>
                    <img style={grupoStyle} src={grupo} />
                    <br/><br/><br/>
                </GridItem>
            </GridContainer>
        </GridItem>


        <GridItem style={{ backgroundColor: "#fdf5ff" }}>
            <GridContainer direction="column"
                justify="space-between"
                alignItems="center">
                <GridItem >
                    <Typography style={redStyle} align="center"  >
                        <br/><br/>
                        Nuestra plataforma te expandir&aacute; a nuevos mercados
                    </Typography >
                </GridItem>
                <GridItem xs={12} sm={9} md={7} lg={6} xl={5} >
                    <Typography align="center" style={{ width: "100%", fontSize: "20px" }}>
                        <br/>
                        Gracias a la facilidad, rapidez y practicidad de la plataforma podr&aacute;s crear campa&ntilde;as para incrementar y crear nuevos mercados.
                    </Typography>
                </GridItem>
            </GridContainer>


            <GridContainer direction="row"
                justify="space-evenly"
                alignItems="center" >
                <GridItem xs={10} sm={5}>
                    <br />
                    <br />
                    <Typography align="center">
                        <AccessTime style={redIcons}  />
                    </Typography>
                    <Typography align="center" style={{ fontSize: 20 }}>
                        Customiza f&aacute;cil y r&aacute;pido
                    </Typography>
                    <Typography align="center">
                        Customiza de manera f&aacute;cil y r&aacute;pida alguna experiencia del cat&aacute;logo de juegos.
                    </Typography>
                </GridItem>
                <GridItem xs={10} sm={5}>
                    <br />
                    <br />
                    <Typography align="center">
                        <OndemandVideoIcon style={redIcons}  />
                    </Typography>
                    <Typography align="center" style={{ fontSize: 20 }}>
                        Configura tu campa&ntilde;a
                    </Typography>
                    <Typography align="center">
                        Define el tiempo de vida de la campa&ntilde;a, donde y quienes podr&aacute;n jugar la experiencia.
                    </Typography>
                </GridItem>
                <GridItem xs={10} sm={5}>
                    <br />
                    <br />
                    <Typography align="center">
                        <TimelineIcon style={redIcons}  />
                    </Typography>
                    <Typography align="center" style={{ fontSize: 20 }}>
                        Medible
                    </Typography >
                    <Typography align="center">
                        Mecanismos de medici&oacute;n  r&aacute;pida, clara y puntual de tus audiencias.
                    </Typography>
                </GridItem>
                <GridItem xs={10} sm={5}>
                    <br />
                    <br/>
                    <Typography align="center">
                        <VideogameAssetIcon style={redIcons}  />
                    </Typography>
                    <Typography align="center" style={{ fontSize: 20 }}>
                        Juegos sin instalaci&oacute;n
                    </Typography>
                    <Typography align="center">
                        Juegos en navegadores web sin instalaci&oacute;n , Instant Play - Instant Win.
                    </Typography>
                </GridItem>
            </GridContainer>
            <br/><br/><br/>
        </GridItem>

        <GridItem style={{ backgroundColor: "#ffffff", borderTop:" 1px solid #d4dcfe"}}>
            <GridContainer direction="column"
                justify="center"
                alignItems="center">
                <GridItem xs={10} sm={8} md={7} lg={5} xl={4}>
                    <Typography style={redStyle} align="center"  >
                        <br /><br />
                        Crea tu primer campa&ntilde;a con juegos y llega a m&aacute;s personas
                    </Typography >
                </GridItem>

                <GridItem xs={5} sm={3} md={3} lg={2} xl={1}>
                    <br/>
                    <Link to={ROUTES.SIGN_IN}>
                        <Button type="button" size="lg" color="danger"> Comenzar</Button>
                    </Link>
                    <br/><br/><br/>
                </GridItem>
            </GridContainer>
        </GridItem>


    </GridContainer>
);


export default compose(
    withRouter,

)(Landing);



//export default Landing;
